import React from 'react';

function Bulb(props) {
  return (
    <svg
      {...props}
      width="73"
      height="54"
      viewBox="0 0 73 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.0495 24.3624L58.9695 30.9076V17.8268L72.0495 24.3624Z"
        fill="#C3262A"
      />
      <g filter="url(#filter0)">
        <path
          d="M6.99341 40.7068V5.50614C6.99341 2.90182 9.10714 0.788086 11.7115 0.788086H56.2513C58.8556 0.788086 60.9694 2.90182 60.9694 5.50614V16.0143V30.1745V40.7068C60.9694 43.3111 58.8556 45.4249 56.2513 45.4249H11.7115C9.10714 45.4249 6.99341 43.3111 6.99341 40.7068Z"
          fill="#C3262A"
        />
        <path
          d="M5.24341 42.7068V7.50614C5.24341 5.59218 6.7975 4.03809 8.71146 4.03809H53.2513C55.1653 4.03809 56.7194 5.59218 56.7194 7.50614V32.1745V42.7068C56.7194 44.6208 55.1653 46.1749 53.2513 46.1749H8.71146C6.7975 46.1749 5.24341 44.6208 5.24341 42.7068Z"
          stroke="white"
          strokeWidth="2.5"
        />
      </g>
      <path
        d="M42.2209 22.1184C42.2209 15.9092 37.1905 10.8788 30.9813 10.8788C24.7722 10.8788 19.7417 15.9163 19.7417 22.1184C19.7417 26.5315 22.2885 30.3341 25.9859 32.1723V34.6069H35.9767V32.1723C39.6741 30.3411 42.2209 26.5315 42.2209 22.1184Z"
        stroke="white"
        strokeWidth="2.87643"
        strokeMiterlimit="10"
      />
      <path
        d="M24.7373 38.3534H37.2258"
        stroke="white"
        strokeWidth="2.87643"
        strokeMiterlimit="10"
      />
      <path
        d="M30.9817 33.358V23.3672"
        stroke="white"
        strokeWidth="2.87643"
        strokeMiterlimit="10"
      />
      <path
        d="M28.4841 23.3672H33.4795"
        stroke="white"
        strokeWidth="2.87643"
        strokeMiterlimit="10"
      />
      <path
        d="M24.7373 22.1184C24.7373 18.6735 27.5297 15.8741 30.9815 15.8741"
        stroke="white"
        strokeWidth="2.87643"
        strokeMiterlimit="10"
      />
      <defs>
        <filter
          id="filter0"
          x="0.91501"
          y="0.788086"
          width="63.1329"
          height="52.7936"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.0784" />
          <feGaussianBlur stdDeviation="1.5392" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_547:3660"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_547:3660"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default Bulb;
