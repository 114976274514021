import cc from 'classcat';
import React from 'react';

import grey from '../../assets/careers/Hero/grey.png';
import largeHex from '../../assets/careers/Hero/largeHex.jpg';
import mediumHex from '../../assets/careers/Hero/mediumHex.jpg';
import AngularIcon from '../../assets/careers/SvgComponents/AngularIcon';
import { Border } from '../../assets/careers/SvgComponents/Border';
import JsIcon from '../../assets/careers/SvgComponents/JsIcon';
import ReactIcon from '../../assets/careers/SvgComponents/ReactIcon';
import VueIcon from '../../assets/careers/SvgComponents/VueIcon';

import * as styles from './index.module.scss';

export function Hexagons() {
  return (
    <section className={styles.hexagons}>
      <div
        className={cc([styles.smallHex, styles.hexContainer, styles.react])}
        key="React"
      >
        <Border className={styles.border} />
        <div className={styles.hexagonImg}>
          <ReactIcon className={styles.icon} />
        </div>
      </div>
      <div
        className={cc([styles.smallHex, styles.hexContainer, styles.angular])}
        key="Angular"
      >
        <Border className={styles.border} />
        <div className={styles.hexagonImg}>
          <AngularIcon className={styles.icon} />
        </div>
      </div>
      <div className={cc([styles.largeHex, styles.hexContainer])} key="Large">
        <Border className={styles.border} />
        <img
          src={largeHex}
          alt="Really Good Team"
          className={styles.hexagonImg}
        />
      </div>
      <div className={cc([styles.mediumHex, styles.hexContainer])} key="Medium">
        <Border className={styles.border} />
        <img
          src={mediumHex}
          alt="Really Good Team"
          className={styles.hexagonImg}
        />{' '}
        <img src={grey} alt="Grey Background" className={styles.grey} />
      </div>
      <div
        className={cc([styles.smallHex, styles.hexContainer, styles.js])}
        key="JS"
      >
        <Border className={styles.border} />
        <div className={styles.hexagonImg}>
          <JsIcon className={styles.icon} />
        </div>
      </div>
      <div
        className={cc([styles.smallHex, styles.hexContainer, styles.vue])}
        key="Vue"
      >
        <Border className={styles.border} />
        <div className={styles.hexagonImg}>
          <VueIcon className={styles.icon} />
        </div>
      </div>
    </section>
  );
}
