import React from 'react';

function CloseButton() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.98709 0.653656C1.72185 0.653726 1.46265 0.732897 1.24264 0.881049C1.02262 1.0292 0.851793 1.2396 0.751983 1.48535C0.652174 1.7311 0.627922 2.00103 0.682327 2.26064C0.736732 2.52024 0.867323 2.75772 1.0574 2.94272L8.1147 10L1.0574 17.0573C0.929439 17.1802 0.827275 17.3273 0.756895 17.4902C0.686516 17.653 0.649335 17.8282 0.64753 18.0056C0.645725 18.183 0.679333 18.359 0.746385 18.5232C0.813437 18.6875 0.912586 18.8367 1.03802 18.9621C1.16346 19.0875 1.31267 19.1867 1.47691 19.2537C1.64114 19.3208 1.81711 19.3544 1.99449 19.3526C2.17188 19.3508 2.34713 19.3136 2.50996 19.2432C2.6728 19.1728 2.81996 19.0707 2.94282 18.9427L10.0001 11.8854L17.0574 18.9427C17.1803 19.0707 17.3274 19.1728 17.4903 19.2432C17.6531 19.3136 17.8283 19.3508 18.0057 19.3526C18.1831 19.3544 18.3591 19.3208 18.5233 19.2537C18.6876 19.1867 18.8368 19.0875 18.9622 18.9621C19.0876 18.8367 19.1868 18.6875 19.2538 18.5232C19.3209 18.359 19.3545 18.183 19.3527 18.0056C19.3509 17.8282 19.3137 17.653 19.2433 17.4902C19.173 17.3273 19.0708 17.1802 18.9428 17.0573L11.8855 10L18.9428 2.94272C19.1355 2.75543 19.2671 2.51431 19.3204 2.25095C19.3736 1.98759 19.3461 1.71428 19.2414 1.46683C19.1367 1.21939 18.9596 1.00935 18.7335 0.864242C18.5074 0.719137 18.2426 0.645735 17.9741 0.653656C17.6277 0.663977 17.2989 0.80875 17.0574 1.0573L10.0001 8.11459L2.94282 1.0573C2.81857 0.929576 2.66997 0.828049 2.50582 0.75872C2.34167 0.689391 2.16528 0.653666 1.98709 0.653656Z"
        fill="white"
      />
    </svg>
  );
}

export default CloseButton;
