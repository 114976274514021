import React from 'react';

export default function JsIcon(props) {
  return (
    <svg
      {...props}
      width="95px"
      height="95px"
      viewBox="0 0 95 95"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Unofficial_JavaScript_logo_2</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Artboard"
          transform="translate(-188.000000, -501.000000)"
          fillRule="nonzero"
        >
          <g id="Polygon-5" transform="translate(131.000000, 456.000000)">
            <g
              id="Unofficial_JavaScript_logo_2"
              transform="translate(57.000000, 45.000000)"
            >
              <rect
                id="Rectangle"
                fill="#F7DF1E"
                x="0"
                y="0"
                width="95"
                height="95"
              ></rect>
              <path
                d="M64.04516,74.7920893 C65.9707122,77.8847315 68.4759026,80.1579429 72.9066452,80.1579429 C76.6287724,80.1579429 79.006503,78.3280301 79.006503,75.7995861 C79.006503,72.7696326 76.5635251,71.6964619 72.4666057,69.9337155 L70.2208868,68.9859222 C63.7386498,66.2694121 59.4323322,62.8663116 59.4323322,55.6720377 C59.4323322,49.0449473 64.5656206,44 72.5879959,44 C78.2994051,44 82.4054288,45.9552902 85.3643151,51.0748669 L78.3692045,55.4929272 C76.8290662,52.7764171 75.1675378,51.7062316 72.5879959,51.7062316 C69.9568631,51.7062316 68.2892652,53.3480783 68.2892652,55.4929272 C68.2892652,58.1437634 69.9583805,59.2169341 73.8125196,60.8587808 L76.0582384,61.8050816 C83.6906477,65.0245938 88,68.3067947 88,75.6861495 C88,83.6416432 81.64674,88 73.1145259,88 C64.7719839,88 59.3822587,84.0894196 56.7450565,78.9638726 L64.04516,74.7920893 Z M32.3122426,75.5577869 C33.7234037,78.020557 35.0071052,80.1027172 38.0934512,80.1027172 C41.0447506,80.1027172 42.9065729,78.9668578 42.9065729,74.55029 L42.9065729,44.5044947 L51.8894482,44.5044947 L51.8894482,74.6696971 C51.8894482,83.8192612 46.4359932,87.9835815 38.4758303,87.9835815 C31.2834606,87.9835815 27.1182591,84.3222633 25,79.9124122 L32.3122426,75.5577869 Z"
                id="Shape"
                fill="#000000"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
