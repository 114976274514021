import React from 'react';

export const Telegram = (props) => (
  <svg
    {...props}
    width="31"
    height="28"
    viewBox="0 0 31 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.1677 0.534564C29.6577 0.102084 28.8567 0.0402039 28.0284 0.372724H28.0271C27.156 0.722244 3.36957 10.925 2.40125 11.3418C2.22513 11.403 0.686974 11.9769 0.845414 13.2553C0.986854 14.4079 2.22309 14.8853 2.37405 14.9404L8.42129 17.011C8.82249 18.3465 10.3015 23.2738 10.6286 24.3264C10.8326 24.9826 11.1651 25.8448 11.7479 26.0223C12.2592 26.2195 12.7679 26.0393 13.097 25.7809L16.7941 22.3517L22.7625 27.0063L22.9046 27.0913C23.3099 27.2708 23.6982 27.3606 24.0688 27.3606C24.3551 27.3606 24.6298 27.3068 24.8923 27.1994C25.7865 26.8322 26.1441 25.9802 26.1815 25.8836L30.6396 2.71124C30.9116 1.47364 30.5335 0.843964 30.1677 0.534564ZM13.7545 17.8392L11.7145 23.2792L9.67453 16.4792L25.3145 4.9192L13.7545 17.8392Z"
      fill="#1E1D1D"
    />
  </svg>
);
