import React from 'react';

export const Logo = (props) => (
  <svg
    {...props}
    viewBox="0 0 93 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.5971 30.7215C92.5971 30.7215 92.5896 26.5252 90.2266 24.987C81.4322 19.9303 51.0575 2.46113 48.8537 1.16932C48.8537 1.16932 45.9469 -0.176287 43.7541 1.15789C43.6737 1.20788 12.1082 19.3983 2.64547 24.8309C-0.099112 26.5165 0.000455641 30.7391 0.000455641 30.7391V76.894C0.000455641 76.894 0.0325125 79.5373 2.98896 81.3864C12.5071 86.8777 43.8075 104.898 43.8227 104.908C44.1695 105.141 46.5037 106.281 48.8914 104.826C49.0946 104.708 80.7464 86.4837 90.0531 81.1265C92.6773 79.4718 92.5971 75.0408 92.5971 75.0408V30.7215ZM54.8689 72.9936C54.6201 72.4921 54.5749 72.3375 54.7882 71.7921C54.9578 71.3606 54.9198 71.0776 54.7971 70.5892C54.632 69.9417 54.0692 69.5276 53.7974 68.9562C53.5197 68.3684 53.7331 67.7967 53.4892 67.1933C53.1019 66.2311 52.1956 66.0866 51.75 65.2019C51.4624 64.6302 51.3598 63.9988 50.8484 63.5542C50.2903 63.0728 49.492 62.9783 48.7876 63.0804C47.7911 63.2234 47.1977 64.0734 46.2989 64.3663L44.4574 64.97C43.1656 65.3929 43.2577 64.4099 43.1144 63.2101C42.79 60.5169 42.6979 57.2583 40.1769 55.9402C39.3686 55.5174 38.1643 55.2025 38.0329 56.6051C37.9934 57.0338 38.5035 57.5995 38.6583 57.9831C38.9651 58.7619 39.199 59.5854 39.3495 60.4151C39.8217 63.0118 39.091 65.8682 39.265 68.5292C39.465 71.5999 39.9329 74.3119 38.5545 77.1508H23.7809V28.8596H46.2212C61.4756 28.8596 69.1013 33.2482 69.1013 42.0158C69.1013 43.7043 68.7654 45.2628 68.0916 46.6902C67.4193 48.119 66.4665 49.4038 65.236 50.5497C64.0053 51.6988 62.5248 52.6875 60.7843 53.5182C59.0424 54.3538 57.1044 55.0042 54.968 55.4763C55.8975 55.8351 56.8021 56.1997 57.6729 56.7025C58.5442 57.2057 59.3917 57.7933 60.2043 58.4668C61.0168 59.1406 61.794 59.8637 62.5366 60.6364C63.2805 61.4091 63.9544 62.1673 64.5694 62.9053L76.3924 77.1506H53.0608C54.2314 76.169 55.6622 74.5917 54.8689 72.9936Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.2938 36.8281H37.8618V50.3385H44.032C47.0851 50.3385 49.5358 49.6647 51.3877 48.3089C53.267 46.9339 54.2081 45.228 54.2081 43.1925C54.2083 38.9526 50.8996 36.8281 44.2938 36.8281Z"
      fill="#fff"
    />
  </svg>
);
