import React from 'react';

import annual from '../../assets/careers/Figures/annual-leave.png';
import childbirth from '../../assets/careers/Figures/childbirth.png';
import competitive from '../../assets/careers/Figures/competitive-salary.png';
import courses from '../../assets/careers/Figures/courses.png';
import food from '../../assets/careers/Figures/food.png';
import highEnd from '../../assets/careers/Figures/high-end.png';
import hybrid from '../../assets/careers/Figures/hybrid-icon.png';
import international from '../../assets/careers/Figures/international.png';
import BenefitFigure from '../BenefitFigure/BenefitFigure';

import * as styles from './index.module.scss';

type Figures = {
  figure: string;
  desc: string;
};

const figures: Figures[] = [
  { figure: hybrid, desc: 'Hybrid job' },
  { figure: competitive, desc: 'Competitive salary' },
  { figure: annual, desc: '20 days annual leave' },
  { figure: highEnd, desc: 'High-end laptop and peripherals' },
  { figure: international, desc: 'International conferences' },
  {
    figure: courses,
    desc: 'Great courses & training',
  },
  { figure: food, desc: 'Generous food budget' },

  { figure: childbirth, desc: '2 extra weeks off after having a child' },
];

function OurBenefits() {
  return (
    <section className={styles.ourBenefits}>
      <h2 className={styles.title}>Our Benefits</h2>
      <div className={styles.figures}>
        {figures.map((figure, index) => (
          <BenefitFigure
            key={index}
            figure={figure.figure}
            desc={figure.desc}
          />
        ))}
      </div>
    </section>
  );
}

export default OurBenefits;
