import React from 'react';

import { About } from '../About/About';
import { Logo } from '../../assets/careers/SvgComponents/Logo';

import * as styles from './index.module.scss';

export function Hero() {
  return (
    <section className={styles.heroAbout}>
      <div className={styles.background} />
      <header className={styles.hero}>
        <Logo className={styles.logo} />
        <h1>Really Good Careers</h1>
        <p>Be a Pro and maintain a healthy Work-Life Balance</p>
        <a href="#requirements">Apply Now</a>
      </header>
      <About />
    </section>
  );
}
