import React from 'react';
import Helmet from 'react-helmet';

import { Footer } from '../components/Footer/Footer';
import Gallery from '../components/Gallery/Gallery';
import { Hero } from '../components/Hero/Hero';
import Navbar from '../components/Navbar/Navbar';
import OurBenefits from '../components/OurBenefits/OurBenefits';
import { Outreach } from '../components/Outreach/Outreach';
import Requirements from '../components/Requirements/Requirements';
import Reviews from '../components/Reviews/Reviews';
import { WorkingOn } from '../components/WorkingOn/WorkingOn';
import { usePageTrack } from '../hooks/usePageTrack';

import '../main.scss';
import * as styles from './index.module.scss';

function Careers() {
  usePageTrack();

  const title = 'Really Good Careers';
  const description =
    'Join Really Good. Be a Pro and maintain a healthy Work-Life Balance';

  return (
    <main className={styles.careers}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="business.business" />
        <meta property="fb:app_id" content="132200783468304" />
        <meta
          property="og:image"
          content="https://cdn.reallygood.co.il/v2/images/beergarden.jpg"
        />
        <meta property="og:type" content="business.business" />
        <meta property="og:url" content="https://careers.reallygood.co.il" />
        <meta
          property="business:contact_data:street_address"
          content="Sarona Tower, 121 Menachem Begin Road"
        />
        <meta property="business:contact_data:locality" content="Tel Aviv" />
        <meta property="business:contact_data:postal_code" content="6701203" />
        <meta property="business:contact_data:country_name" content="Israel" />
        <meta property="place:location:latitude" content="32.08257691110207" />
        <meta property="place:location:longitude" content="34.80142779433295" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="shortcut icon" href="favicon.ico" />
        <script src="//embed.typeform.com/next/embed.js"></script>
      </Helmet>
      <Navbar />
      <Hero />
      <WorkingOn />
      <OurBenefits />
      <Requirements />
      <Reviews />
      <Gallery />
      <Outreach />
      <Footer />
    </main>
  );
}

export default Careers;
