import React from 'react';

export const LogoFull = (props) => (
  <svg
    {...props}
    viewBox="0 0 862 300"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Really Good - Light Copy</title>
    <g
      id="Really-Good---Light-Copy"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-2" transform="translate(18.000000, 11.000000)">
        <g
          id="full-reallygood"
          transform="translate(295.000000, 89.000000)"
          fill="#2E2F2C"
        >
          <path
            d="M1.7417,96.4884 C2.5417,97.0944 3.5767,97.3744 4.7997,97.3744 C5.9217,97.3744 6.9187,97.0944 7.7707,96.4884 C8.6177,95.9104 9.0707,95.1064 9.0707,94.0944 L9.0707,55.3224 L25.0337,55.3224 L44.4967,93.2094 C45.9117,96.1514 47.3667,97.6144 48.9077,97.6144 C50.1067,97.6144 51.2427,97.1814 52.2827,96.2954 C53.3277,95.4194 53.8577,94.4324 53.8577,93.3294 C53.8577,92.6744 53.6747,92.1154 53.3467,91.6824 L33.7057,54.3354 C46.9567,52.0144 53.5827,43.6454 53.5827,29.2484 C53.5827,20.3684 51.0507,13.9834 46.0177,10.0834 C40.9867,6.1684 34.3457,4.2184 26.1647,4.2184 L3.6967,4.2184 C1.5537,4.3674 0.5187,5.4034 0.5187,7.2614 L0.5187,94.0614 C0.5187,95.0874 0.9287,95.8964 1.7417,96.4884 L1.7417,96.4884 Z M9.0707,11.2674 L26.1647,11.2674 C38.7417,11.2674 45.0267,17.4074 45.0267,29.6334 C45.0267,42.0754 38.7417,48.2784 26.1647,48.2784 L9.0707,48.2784 L9.0707,11.2674 Z"
            id="Fill-3"
          ></path>
          <path
            d="M69.6997,92.7228 C74.7217,97.1818 81.2207,99.3868 89.1947,99.3868 C94.6257,99.3868 99.1617,98.4478 102.8407,96.6038 C106.4807,94.7258 108.3207,92.9348 108.3207,91.2108 C108.3207,90.3728 107.9157,89.5058 107.1747,88.6158 C106.3947,87.7238 105.5087,87.2718 104.5067,87.2718 C103.8327,87.2718 102.9467,87.7148 101.8097,88.5338 C100.6927,89.3808 99.0657,90.2288 96.9177,91.0758 C94.7797,91.9188 92.2657,92.3378 89.4447,92.3378 C83.9267,92.3378 79.4097,90.8448 75.9257,87.8498 C72.4447,84.8348 70.7057,80.8528 70.7057,75.8688 L70.7057,71.1888 L99.1427,71.1888 C100.9047,71.1888 102.2627,71.1408 103.2937,70.9628 C104.2847,70.8038 105.3837,70.4428 106.5967,69.8788 C107.8387,69.3398 108.7247,68.3678 109.2447,67.0288 C109.7837,65.6708 110.0827,63.8988 110.0827,61.6448 C110.0827,54.8218 107.6947,49.1298 102.9947,44.5508 C98.2757,39.9908 92.6897,37.6938 86.2567,37.6938 C79.5827,37.6938 73.9277,40.0058 69.2037,44.5988 C64.5037,49.2308 62.1547,54.7158 62.1547,61.0578 L62.1547,75.2628 C62.1547,82.4668 64.6577,88.2828 69.6997,92.7228 L69.6997,92.7228 Z M70.7057,60.8848 C70.7057,56.4828 72.1987,52.6988 75.1937,49.5108 C78.1987,46.3418 81.8267,44.7438 86.1027,44.7438 C90.5527,44.7438 94.2207,46.4288 97.1297,49.7898 C100.0477,53.1508 101.5117,57.0028 101.5117,61.4048 C101.5117,63.2828 101.0977,64.4858 100.2987,65.0398 C99.4607,65.6178 97.8237,65.9018 95.3667,65.9018 L70.7057,65.9018 L70.7057,60.8848 Z"
            id="Fill-5"
          ></path>
          <path
            d="M121.7944,95.1447 C125.0414,97.9427 129.2434,99.3867 134.3764,99.3867 C141.3874,99.3867 147.6664,96.1367 153.2524,89.5737 L153.2524,94.6347 C153.2524,95.4777 153.6274,96.1897 154.3884,96.7677 C155.1394,97.3357 156.0644,97.6147 157.2204,97.6147 C158.4524,97.6147 159.4354,97.3217 160.2434,96.6907 C161.0434,96.0837 161.4474,95.3037 161.4474,94.3787 L161.4474,61.8567 C161.4474,54.8557 159.6944,49.0777 156.2474,44.5367 C152.7714,39.9907 147.3194,37.6937 139.8954,37.6937 C135.9754,37.6937 132.3154,38.3537 128.8974,39.6727 C125.4754,41.0017 123.7774,42.4607 123.7774,44.0067 C123.7774,45.1577 124.0374,46.2067 124.5894,47.1417 C125.1284,48.1047 125.8694,48.5767 126.7944,48.5767 C126.9394,48.5767 128.2704,47.9457 130.7834,46.6697 C133.3074,45.4077 136.1194,44.7437 139.2104,44.7437 C143.8434,44.7437 147.2624,46.5347 149.5154,50.1227 C151.7594,53.6897 152.8764,57.8897 152.8764,62.7287 L152.8764,65.9017 L146.4344,65.9017 C136.8134,65.9017 129.4834,67.1347 124.4444,69.5897 C119.4054,72.0367 116.9304,76.4087 116.9304,82.6877 C116.9304,88.1867 118.5384,92.3377 121.7944,95.1447 M127.2284,76.2017 C128.4144,74.7047 130.1294,73.6057 132.3734,72.9517 C134.6164,72.2767 136.8994,71.8147 139.2004,71.5747 C141.4934,71.3287 144.2954,71.1887 147.6374,71.1887 L152.8764,71.1887 L152.8764,79.1967 C152.8764,82.1237 151.1044,85.0277 147.5694,87.9557 C144.0354,90.8737 140.2894,92.3377 136.3694,92.3377 C133.1244,92.3377 130.4664,91.4897 128.4914,89.7667 C126.4864,88.0517 125.4844,85.4807 125.4844,81.9507 C125.4844,79.6347 126.0534,77.7187 127.2284,76.2017"
            id="Fill-7"
          ></path>
          <path
            d="M178.1079,96.6037 C178.9369,97.2827 179.9279,97.6147 181.0849,97.6147 C182.2789,97.6147 183.2899,97.2827 184.1659,96.6037 C184.9849,95.9387 185.4089,95.1787 185.4089,94.3117 L185.4089,3.8527 C185.4089,3.0337 184.9849,2.3117 184.1659,1.7047 C183.2899,1.0977 182.2789,0.8097 181.0459,0.8097 C179.8229,0.8097 178.8399,1.0977 178.0319,1.7047 C177.2319,2.3117 176.8469,3.0337 176.8469,3.8527 L176.8469,94.3117 C176.8469,95.1787 177.2519,95.9387 178.1079,96.6037"
            id="Fill-9"
          ></path>
          <path
            d="M203.7837,96.6037 C204.6217,97.2827 205.6127,97.6147 206.7597,97.6147 C207.9537,97.6147 208.9937,97.2827 209.8417,96.6037 C210.6497,95.9387 211.0837,95.1787 211.0837,94.3117 L211.0837,3.8527 C211.0837,3.0337 210.6497,2.3117 209.8417,1.7047 C208.9937,1.0977 207.9537,0.8097 206.7207,0.8097 C205.5077,0.8097 204.5147,1.0977 203.7067,1.7047 C202.9067,2.3117 202.5317,3.0337 202.5317,3.8527 L202.5317,94.3117 C202.5317,95.1787 202.9357,95.9387 203.7837,96.6037"
            id="Fill-11"
          ></path>
          <path
            d="M221.7251,42.7887 L241.8141,96.0307 C240.1671,102.9597 237.7311,108.7767 234.4861,113.4767 C231.2401,118.1567 227.7351,120.5257 223.9591,120.5257 C222.3121,120.5257 221.5031,121.7107 221.5031,124.1087 C221.5031,126.4247 222.7271,127.5757 225.1921,127.5757 C227.0501,127.5757 228.9001,127.1707 230.7301,126.3957 C232.5391,125.5777 234.0721,124.7247 235.3621,123.8097 C236.6331,122.8467 237.9711,121.3257 239.3591,119.2357 L242.5651,114.2037 C243.3651,112.9367 244.3951,110.8667 245.5991,108.0067 C246.7841,105.1367 247.5821,103.1527 247.9961,102.0497 L250.2311,95.8577 L269.3291,42.7887 C269.4831,42.6157 269.5701,42.2877 269.5701,41.7627 C269.5701,40.6697 268.9821,39.7257 267.8941,38.9077 C266.7861,38.1077 265.6981,37.6937 264.6481,37.6937 C263.3001,37.6937 262.4131,38.3007 261.9421,39.4857 L246.0331,86.2127 L229.1501,39.4857 C228.7931,38.3007 227.8601,37.6937 226.3091,37.6937 C225.1441,37.6937 224.0461,38.1077 222.9671,38.9077 C221.8981,39.7257 221.3781,40.6457 221.3781,41.6427 C221.3781,41.9507 221.5031,42.3597 221.7251,42.7887"
            id="Fill-13"
          ></path>
          <path
            d="M310.6528,91.0856 C315.3908,95.4386 321.5648,97.6146 329.2118,97.6146 C336.8388,97.6146 343.0108,95.4776 347.7588,91.1246 C352.5068,86.7716 354.8858,79.9146 354.8858,70.5436 L354.8858,51.5426 C354.8858,49.3666 353.9038,48.2776 351.9488,48.2776 L330.4438,48.2776 C329.5488,48.2776 328.8158,48.6246 328.2668,49.3176 C327.7478,50.0406 327.5068,50.8346 327.5068,51.7356 C327.5068,52.7276 327.7478,53.5846 328.2668,54.2776 C328.7878,55.0006 329.4708,55.3226 330.3668,55.3226 L346.3248,55.3226 L346.3248,70.7316 C346.3248,77.4006 344.8218,82.3696 341.8658,85.6346 C338.8808,88.9476 334.6718,90.5656 329.2118,90.5656 C323.7698,90.5656 319.5428,88.9476 316.5568,85.6726 C313.5618,82.4186 312.0978,77.4586 312.0978,70.8566 L312.0978,30.8656 C312.0978,17.8116 317.7898,11.2676 329.2118,11.2676 C339.4868,11.2676 345.2078,16.1356 346.3248,25.8576 C346.4118,27.8906 347.8458,28.8816 350.5998,28.8816 C353.4418,28.8816 354.8858,27.5436 354.8858,24.8466 C354.8858,18.8086 352.5458,13.8386 347.8558,9.9916 C343.1468,6.1446 336.8958,4.2186 329.0668,4.2186 C321.3238,4.2186 315.1408,6.3946 310.4898,10.7386 C305.8288,15.0906 303.5268,21.9186 303.5268,31.1986 L303.5268,70.4956 C303.5268,79.8946 305.8858,86.7326 310.6528,91.0856"
            id="Fill-15"
          ></path>
          <path
            d="M374.0317,92.0822 C378.8277,96.9502 384.4037,99.3872 390.7207,99.3872 C397.1057,99.3872 402.7117,96.9502 407.5747,92.0822 C412.3707,87.2332 414.8067,81.6142 414.8067,75.2632 L414.8067,61.4382 C414.8067,54.9472 412.3707,49.3372 407.5077,44.6862 C402.6437,40.0432 397.0107,37.6942 390.7207,37.6942 C384.4037,37.6942 378.8277,40.0582 374.0317,44.7432 C369.2457,49.4622 366.8767,55.0092 366.8767,61.4382 L366.8767,75.2632 C366.8767,81.6142 369.2457,87.2332 374.0317,92.0822 L374.0317,92.0822 Z M375.4277,61.2652 C375.4277,57.0372 376.9297,53.1992 379.9257,49.8092 C382.9207,46.4482 386.5037,44.7432 390.7207,44.7432 C394.8817,44.7432 398.5217,46.4482 401.6137,49.8092 C404.6947,53.1992 406.2457,57.0372 406.2457,61.2652 L406.2457,75.4162 C406.2457,79.8562 404.7337,83.7902 401.6807,87.2042 C398.6187,90.6332 394.9777,92.3372 390.7207,92.3372 C386.5037,92.3372 382.9207,90.6332 379.9257,87.2042 C376.9297,83.7902 375.4277,79.8562 375.4277,75.4162 L375.4277,61.2652 Z"
            id="Fill-17"
          ></path>
          <path
            d="M432.2573,92.0822 C437.0433,96.9502 442.6013,99.3872 448.9083,99.3872 C455.3033,99.3872 460.9273,96.9502 465.7723,92.0822 C470.5873,87.2332 473.0043,81.6142 473.0043,75.2632 L473.0043,61.4382 C473.0043,54.9472 470.5873,49.3372 465.7143,44.6862 C460.8313,40.0432 455.2363,37.6942 448.9083,37.6942 C442.6013,37.6942 437.0433,40.0582 432.2573,44.7432 C427.4713,49.4622 425.0823,55.0092 425.0823,61.4382 L425.0823,75.2632 C425.0823,81.6142 427.4713,87.2332 432.2573,92.0822 L432.2573,92.0822 Z M433.6443,61.2652 C433.6443,57.0372 435.1473,53.1992 438.1423,49.8092 C441.1373,46.4482 444.7193,44.7432 448.9083,44.7432 C453.0983,44.7432 456.7293,46.4482 459.8293,49.8092 C462.9113,53.1992 464.4623,57.0372 464.4623,61.2652 L464.4623,75.4162 C464.4623,79.8562 462.9313,83.7902 459.8773,87.2042 C456.8343,90.6332 453.1943,92.3372 448.9083,92.3372 C444.7193,92.3372 441.1373,90.6332 438.1423,87.2042 C435.1473,83.7902 433.6443,79.8562 433.6443,75.4162 L433.6443,61.2652 Z"
            id="Fill-19"
          ></path>
          <path
            d="M490.0308,92.0822 C494.5188,96.9502 499.8158,99.3872 505.8538,99.3872 C509.5998,99.3872 513.0668,98.4672 516.1778,96.6902 C519.3178,94.8992 521.5998,92.7232 523.0448,90.1322 L523.0448,94.8512 C523.0448,95.5592 523.4488,96.2232 524.2968,96.7682 C525.1248,97.3352 526.0498,97.6152 527.1368,97.6152 C528.2448,97.6152 529.2268,97.2832 530.0168,96.6232 C530.8158,95.9582 531.2208,95.2272 531.2208,94.3792 L531.2208,5.4512 C531.2208,4.6322 530.8358,3.9002 530.0848,3.3232 C529.3428,2.7542 528.3508,2.4562 527.1368,2.4562 C525.8948,2.4562 524.8358,2.7542 523.9598,3.3232 C523.1018,3.9002 522.6588,4.5992 522.6588,5.4032 L522.6588,46.8332 C521.2148,44.2712 519.0668,42.0952 516.1778,40.3422 C513.2888,38.5802 509.9468,37.6942 506.1338,37.6942 C499.8158,37.6942 494.4318,40.0432 489.9828,44.6862 C485.5048,49.3372 483.2798,54.9472 483.2798,61.4382 L483.2798,75.2632 C483.2798,81.6142 485.5238,87.2332 490.0308,92.0822 L490.0308,92.0822 Z M491.8418,61.2652 C491.8418,57.0372 493.3828,53.1992 496.4738,49.8092 C499.5748,46.4482 503.2538,44.7432 507.4908,44.7432 C511.4978,44.7432 515.0318,46.2552 518.0848,49.2892 C521.1468,52.3132 522.6588,56.3192 522.6588,61.2652 L522.6588,79.4802 C522.6588,81.8112 521.1188,84.5462 518.0078,87.6572 C514.9068,90.7912 511.3728,92.3372 507.3948,92.3372 C503.2158,92.3372 499.5748,90.5942 496.4738,87.1372 C493.3828,83.6502 491.8418,79.7692 491.8418,75.4162 L491.8418,61.2652 Z"
            id="Fill-21"
          ></path>
        </g>
        <g id="Group">
          <polygon
            id="Rectangle-1"
            fill="#FFFFFF"
            points="43.2977521 61.7945757 205.808727 61.7945757 205.808727 226.51037 43.2977521 226.51037"
          ></polygon>
          <path
            d="M245.966812,80.1107135 C245.966812,80.1107135 245.947075,68.9642374 239.670145,64.8785464 C216.309992,51.4467388 135.627381,5.04415897 129.773386,1.61278616 C129.773386,1.61278616 122.05227,-1.96149018 116.227599,1.5824051 C116.014209,1.71521437 32.1682683,50.0334566 7.03291339,64.4639011 C-0.257371488,68.9411703 0.00710434783,80.1574104 0.00710434783,80.1574104 L0.00710434783,202.75627 C0.00710434783,202.75627 0.0922554166,209.777673 7.94532683,214.689279 C33.2278634,229.275567 116.369428,277.142283 116.409802,277.169407 C117.331212,277.788418 123.531322,280.814313 129.873762,276.951338 C130.413429,276.636838 214.488549,228.229108 239.209427,213.998953 C246.179972,209.603825 245.966812,197.833975 245.966812,197.833975 L245.966812,80.1107135 Z"
            id="Shape"
            fill="#CE202A"
          ></path>
          <path
            d="M145.751335,192.396326 C145.090428,191.06406 144.970314,190.653353 145.537048,189.204626 C145.987615,188.058585 145.886675,187.306935 145.560732,186.009551 C145.122007,184.289645 143.62707,183.189738 142.905259,181.67181 C142.16766,180.11056 142.734393,178.592069 142.086456,176.989187 C141.057876,174.433239 138.650526,174.049537 137.466869,171.699505 C136.702765,170.181015 136.430395,168.503867 135.071925,167.322944 C133.589394,166.044126 131.469076,165.793201 129.598008,166.06438 C126.950994,166.444143 125.374854,168.701907 122.98724,169.48 L118.095847,171.083445 C114.664428,172.206982 114.909167,169.595898 114.528525,166.408699 C113.666864,159.255083 113.422125,150.599291 106.725642,147.098155 C104.57882,145.97518 101.379734,145.138576 101.030671,148.864195 C100.925783,150.002922 102.280869,151.50566 102.691962,152.524551 C103.506819,154.593276 104.128252,156.780713 104.528068,158.984466 C105.782213,165.882094 103.84122,173.469484 104.30363,180.537583 C104.834837,188.694337 106.077704,195.898026 102.416208,203.438719 L63.1739717,203.438719 L63.1739717,75.1653509 L122.780848,75.1653509 C163.30035,75.1653509 183.556154,86.822679 183.556154,110.111455 C183.556154,114.596601 182.664042,118.736303 180.874177,122.527747 C179.088261,126.32313 176.557413,129.735937 173.288965,132.77967 C170.019954,135.831842 166.087215,138.458116 161.464245,140.664682 C156.837328,142.884188 151.689354,144.611971 146.014683,145.866033 C148.4835,146.819099 150.886339,147.787355 153.199516,149.122996 C155.513821,150.459763 157.764967,152.02045 159.923631,153.809557 C162.081731,155.599227 164.146222,157.519986 166.118794,159.572396 C168.09475,161.624806 169.884614,163.638958 171.518274,165.5991 L202.923229,203.438157 L140.948477,203.438157 C144.0579,200.831011 147.858683,196.641237 145.751335,192.396326 Z"
            id="Path"
            fill="#FFFFFF"
          ></path>
          <path
            d="M117.661124,96.3317584 L100.57621,96.3317584 L100.57621,132.218551 L116.965817,132.218551 C125.07547,132.218551 131.585297,130.428881 136.504322,126.827599 C141.496092,123.175119 143.995925,118.643839 143.995925,113.237135 C143.996488,101.97476 135.207883,96.3317584 117.661124,96.3317584 L117.661124,96.3317584 Z"
            id="Shape"
            fill="#CE202A"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
