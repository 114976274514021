import React from 'react';

import * as styles from './index.module.scss';

export function Outreach() {
  return (
    <section className={styles.container}>
      <div className={styles.background}>
        <div className={styles.textContent}>
          <h3>Giving Back</h3>
          <h2>Community Outreach</h2>
          <p>
            Everyone has started somewhere. We love to share knowledge in our{' '}
            social media channels, speak at events, write for various
            publications &amp; in general welcome newcomers. We’re proud
            sponsors of{' '}
            <a
              href="https://hebdevbook.com/product/%d7%9c%d7%9c%d7%9e%d7%95%d7%93-%d7%92%d7%b3%d7%90%d7%95%d7%95%d7%94%d7%a1%d7%a7%d7%a8%d7%99%d7%a4%d7%98-%d7%91%d7%a2%d7%91%d7%a8%d7%99%d7%aa-%d7%93%d7%99%d7%92%d7%99%d7%98%d7%9c%d7%99"
              target="_blank"
              onClick={() => {
                analytics.track('External Link Clicked', {
                  name: 'Hebdevbook.com JS',
                });
              }}
            >
              Javascript in Hebrew book
            </a>{' '}
            and{' '}
            <a
              href="https://hebdevbook.com/product/react"
              target="_blank"
              onClick={() => {
                analytics.track('External Link Clicked', {
                  name: 'Hebdevbook.com React',
                });
              }}
            >
              React in Hebrew
            </a>
            ,{' '}
            <a
              href="https://headstart.co.il/project/58147"
              target="_blank"
              onClick={() => {
                analytics.track('External Link Clicked', {
                  name: 'Codly',
                });
              }}
            >
              Codly app
            </a>{' '}
            and led a series of meetups to familiarize women with the Front End
            world with much more to come.
          </p>
        </div>
      </div>
    </section>
  );
}
