import React from 'react';

export const Tailormed = (props) => (
  <svg
    {...props}
    width="180px"
    height="27px"
    viewBox="0 0 180 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>TailorMed Logo</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Clientelle"
        transform="translate(-61.000000, -306.000000)"
        fill="#191938"
        fillRule="nonzero"
      >
        <g id="logo-(1)" transform="translate(61.000000, 306.000000)">
          <polygon
            id="Path"
            points="8.18867925 2.26415094 8.18867925 25.9245283 6.52830189 25.9245283 6.52830189 2.26415094 0 2.26415094 0 0.754716981 14.7169811 0.754716981 14.7169811 2.26415094"
          ></polygon>
          <path
            d="M29.6981132,17.6603774 L18.9811321,17.6603774 L15.6226415,25.9245283 L13.8113208,25.9245283 L24.4150943,0 L34.8679245,25.9245283 L33.0566038,25.9245283 L29.6981132,17.6603774 Z M29.0943396,16.1509434 L24.3773585,4.22641509 L19.5849057,16.1509434 L29.0943396,16.1509434 Z"
            id="Shape"
          ></path>
          <polygon
            id="Path"
            points="40.4528302 0.754716981 40.4528302 25.9245283 38.7924528 25.9245283 38.7924528 0.754716981"
          ></polygon>
          <polygon
            id="Path"
            points="48.9056604 0.754716981 48.9056604 24.4150943 55.9245283 24.4150943 55.9245283 25.9245283 47.245283 25.9245283 47.245283 0.754716981"
          ></polygon>
          <path
            d="M83.4377358,13.3962264 C83.4377358,20.6415094 77.7396226,26.3396226 70.3811321,26.3396226 C63.0226415,26.3396226 57.3245283,20.6415094 57.3245283,13.3962264 C57.3245283,6.18526327 63.1701689,0.339622642 70.3811321,0.339622642 C77.5920952,0.339622642 83.4377358,6.18526327 83.4377358,13.3962264 Z M81.7018868,13.3962264 C81.7018868,6.94339623 76.8716981,1.9245283 70.3811321,1.9245283 C63.890566,1.9245283 59.0603774,6.94339623 59.0603774,13.3962264 C59.0603774,19.8490566 63.890566,24.8301887 70.3811321,24.8301887 C76.8716981,24.8301887 81.7018868,19.8113208 81.7018868,13.3962264 L81.7018868,13.3962264 Z"
            id="Shape"
          ></path>
          <path
            d="M99.4339623,25.9245283 L91.2830189,14.0377358 L90.5283019,14.0377358 L90.5283019,25.9245283 L88.8679245,25.9245283 L88.8679245,0.754716981 L92.490566,0.754716981 C94.4150943,0.754716981 96.1509434,0.905660377 97.8113208,2.03773585 C99.7735849,3.39622642 100.415094,5.47169811 100.415094,7.43396226 C100.415094,9.9245283 99.3207547,12.490566 96.1886792,13.5471698 C95.1909089,13.8784342 94.1456438,14.0441469 93.0943396,14.0377358 L101.320755,25.9245283 L99.4339623,25.9245283 Z M90.5283019,12.5283019 L92.9433962,12.5283019 C96.5283019,12.5283019 98.6792453,10.6415094 98.6792453,7.39622642 C98.6792453,4.86792453 97.4339623,3.54716981 96.4528302,3.01886792 C95.3962264,2.41509434 93.5849057,2.26415094 92.6037736,2.26415094 L90.5283019,2.26415094 L90.5283019,12.5283019 Z"
            id="Shape"
          ></path>
          <polygon
            id="Path"
            points="104.716981 25.9245283 108.754717 0.754716981 113.584906 0.754716981 119.849057 15.8113208 126.113208 0.754716981 130.943396 0.754716981 134.981132 25.9245283 129.169811 25.9245283 127.169811 10.9056604 120.867925 25.9245283 118.830189 25.9245283 112.528302 10.9056604 110.528302 25.9245283"
          ></polygon>
          <polygon
            id="Path"
            points="153.54717 5.66037736 145.132075 5.66037736 145.132075 10.5283019 153.132075 10.5283019 153.132075 15.4339623 145.132075 15.4339623 145.132075 21.0188679 153.54717 21.0188679 153.54717 25.9245283 139.320755 25.9245283 139.320755 0.754716981 153.54717 0.754716981"
          ></polygon>
          <path
            d="M166.226415,0.754716981 C169.54717,0.754716981 173.056604,1.24528302 176.150943,4.22641509 C178.377358,6.37735849 179.773585,9.32075472 179.773585,13.245283 C179.773585,17.1698113 178.415094,20.0754717 176.339623,22.1509434 C172.867925,25.6603774 168.528302,25.9245283 166.415094,25.9245283 L158.264151,25.9245283 L158.264151,0.754716981 L166.226415,0.754716981 Z M164.075472,21.0188679 L165.622642,21.0188679 C166.830189,21.0188679 169.471698,20.9056604 171.358491,19.3207547 C172.716981,18.1886792 173.811321,16.0377358 173.811321,13.245283 C173.811321,10.5283019 172.716981,8.79245283 171.735849,7.81132075 C169.849057,5.9245283 167.396226,5.66037736 165.622642,5.66037736 L164.075472,5.66037736 L164.075472,21.0188679 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
