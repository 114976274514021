import React from 'react';
import * as styles from './index.module.scss';

interface Figures {
  figure: string;
  desc: string;
}

function BenefitFigure({ figure, desc }: Figures) {
  return (
    <div className={styles.wrapper}>
      <img src={figure} alt="figure" className={styles.image} />
      <p>{desc}</p>
    </div>
  );
}

export default BenefitFigure;
