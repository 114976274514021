import React from 'react';

function ReviewBorder(props) {
  return (
    <svg
      {...props}
      width="546"
      height="285"
      viewBox="0 0 546 285"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.06641 1.68286H485.589C517.897 1.68286 544.089 27.8742 544.089 60.1829V282.797H60.5664C28.2577 282.797 2.06641 256.605 2.06641 224.297V1.68286Z"
        stroke="#333333"
        strokeWidth="3"
      />
    </svg>
  );
}

export default ReviewBorder;
