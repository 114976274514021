import React from 'react';

export const Walkme = (props) => (
  <svg
    {...props}
    width="180px"
    height="60px"
    viewBox="0 0 180 60"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>WalkMe Logo</title>
    <defs>
      <polygon
        id="path-1"
        points="0.0287234043 0.0775531915 35.387234 0.0775531915 35.387234 25.7510106 0.0287234043 25.7510106"
      ></polygon>
      <polygon
        id="path-3"
        points="0.311648936 0.239361702 107.967926 0.239361702 107.967926 59.8117021 0.311648936 59.8117021"
      ></polygon>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Clientelle" transform="translate(-61.000000, -74.000000)">
        <g id="WalkMeLogo" transform="translate(61.000000, 74.000000)">
          <g id="Group-3" transform="translate(0.000000, 13.882979)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-2"></g>
            <path
              d="M35.387234,0.0775531915 L35.387234,14.6489362 C35.387234,18.0756383 34.487234,20.7832979 32.6886702,22.77 C30.8901064,24.7581383 28.4739894,25.7510106 25.4417553,25.7510106 C23.8648404,25.7510106 22.4176596,25.4206915 21.0987766,24.7614894 C19.7784574,24.102766 18.6477128,23.1390957 17.7065426,21.8704787 C16.695,23.1558511 15.5379255,24.1238298 14.2367553,24.7744149 C12.9346277,25.4254787 11.5128191,25.7510106 9.97037234,25.7510106 C6.9381383,25.7510106 4.51819149,24.7533511 2.71053191,22.7570745 C0.90287234,20.7617553 0,18.058883 0,14.6489362 L0,0.0775531915 L4.49617021,0.0775531915 L4.49617021,15.4455319 C4.49617021,17.2278191 4.99835106,18.6625532 6.00031915,19.7506915 C7.00276596,20.8388298 8.32595745,21.3821809 9.97037234,21.3821809 C11.5975532,21.3821809 12.9264894,20.8340426 13.9543085,19.737766 C14.9821277,18.6414894 15.4962766,17.2110638 15.4962766,15.4455319 L15.4962766,0.0775531915 L19.9675532,0.0775531915 L19.9675532,15.4455319 C19.9675532,17.2278191 20.4687766,18.6625532 21.4707447,19.7506915 C22.4736702,20.8388298 23.796383,21.3821809 25.4417553,21.3821809 C27.0512234,21.3821809 28.3662766,20.8340426 29.3864362,19.737766 C30.4046809,18.6414894 30.915,17.2110638 30.915,15.4455319 L30.915,0.0775531915 L35.387234,0.0775531915"
              id="Fill-1"
              fill="#161616"
              mask="url(#mask-2)"
            ></path>
          </g>
          <path
            d="M57.3625532,39.1715426 L57.3625532,26.6045745 C57.3625532,23.9668085 56.6176596,21.8288298 55.1264362,20.1925532 C53.6361702,18.557234 51.7002128,17.738617 49.3190426,17.738617 C46.9551064,17.738617 45.013883,18.5481383 43.497766,20.1667021 C41.9816489,21.7862234 41.2238298,23.8725 41.2238298,26.4245745 C41.2238298,29.0293085 42.0027128,31.1625 43.5623936,32.8241489 C45.121117,34.4862766 47.1087766,35.3168617 49.5239362,35.3168617 C50.6211702,35.3168617 51.6872872,35.1287234 52.7242021,34.7514894 C53.7596809,34.374734 54.8009043,33.7926064 55.8464362,33.0036702 L55.8464362,37.8612766 C54.8181383,38.4434043 53.7347872,38.8761702 52.5954255,39.158617 C51.4555851,39.4410638 50.2606915,39.5832447 49.0107447,39.5832447 C47.8110638,39.5832447 46.6204787,39.3989362 45.4380319,39.0303191 C44.2565426,38.6621809 43.1851596,38.1437234 42.2253191,37.4754255 C40.4095213,36.225 39.0221809,34.6619681 38.0632979,32.7858511 C37.1029787,30.909734 36.6237766,28.8239362 36.6237766,26.5279787 C36.6237766,22.7589894 37.8143617,19.6444149 40.1960106,17.1861702 C42.5771809,14.7274468 45.6012766,13.4985638 49.2673404,13.4985638 C51.6834574,13.4985638 53.8937234,14.1065426 55.8981383,15.3229787 C57.9025532,16.5394149 59.4445213,18.2101596 60.5230851,20.3337766 C61.02,21.2945745 61.3761702,22.3429787 61.5901596,23.4828191 C61.8041489,24.6221809 61.911383,26.3221277 61.911383,28.5840957 L61.911383,39.1715426 L57.3625532,39.1715426"
            id="Fill-4"
            fill="#161616"
          ></path>
          <polygon
            id="Fill-5"
            fill="#161616"
            points="64.5357447 39.1715426 69.0592021 39.1715426 69.0592021 2.3706383 64.5357447 2.3706383"
          ></polygon>
          <g id="Group-9" transform="translate(71.808511, 0.000000)">
            <mask id="mask-4" fill="white">
              <use xlinkHref="#path-3"></use>
            </mask>
            <g id="Clip-7"></g>
            <path
              d="M0.311648936,39.1715426 L0.311648936,2.3706383 L4.83462766,2.3706383 L4.83462766,23.1098936 L6.83952128,23.1098936 C8.12441489,23.1098936 9.1737766,22.8446809 9.98760638,22.3132979 C10.8004787,21.7823936 11.6789362,20.7373404 12.6210638,19.1776596 L15.8337766,13.9605319 L21.1021277,13.9605319 L17.4015957,19.948883 C16.7500532,21.0279255 16.1080851,21.9193085 15.4737766,22.6215957 C14.8399468,23.323883 14.2405851,23.8212766 13.6752128,24.1118617 C15.7480851,24.5068085 17.2771277,25.3584574 18.2628191,26.6692021 C19.2470745,27.9794681 19.7401596,29.8345213 19.7401596,32.2329255 L19.7401596,39.1715426 L15.1913298,39.1715426 L15.1913298,32.7978191 C15.1913298,30.9825 14.7935106,29.649734 13.9964362,28.8019149 C13.1998404,27.9545745 11.9364894,27.5299468 10.2059043,27.5299468 L4.83462766,27.5299468 L4.83462766,39.1715426 L0.311648936,39.1715426"
              id="Fill-6"
              fill="#161616"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M90.773617,0.239361702 L39.7187234,0.239361702 C30.222766,0.239361702 22.5239362,7.93723404 22.5239362,17.4331915 L22.5239362,34.0204787 C22.5239362,43.5173936 30.222766,51.215266 39.7187234,51.215266 L52.9448936,51.215266 L65.0575532,59.8404255 L77.169734,51.215266 L90.773617,51.215266 C100.269574,51.215266 107.967926,43.5173936 107.967926,34.0204787 L107.967926,17.4331915 C107.967926,7.93723404 100.269574,0.239361702 90.773617,0.239361702"
              id="Fill-8"
              fill="#3688BD"
              mask="url(#mask-4)"
            ></path>
          </g>
          <path
            d="M106.047766,38.615266 L106.047766,24.043883 C106.047766,20.6176596 106.947287,17.9109574 108.745851,15.9232979 C110.544894,13.936117 112.969149,12.9422872 116.018138,12.9422872 C117.577819,12.9422872 119.016862,13.2726064 120.335745,13.9318085 C121.655585,14.5914894 122.794468,15.5546809 123.75383,16.8228191 C124.747181,15.5374468 125.89516,14.5699468 127.197766,13.918883 C128.499415,13.2682979 129.930319,12.9422872 131.489521,12.9422872 C134.505,12.9422872 136.915851,13.9399468 138.723511,15.9362234 C140.530213,17.9320213 141.435479,20.6348936 141.435479,24.043883 L141.435479,38.615266 L136.963245,38.615266 L136.963245,23.1960638 C136.963245,21.4324468 136.457713,20.0096809 135.447128,18.9296809 C134.436064,17.8506383 133.116223,17.311117 131.489521,17.311117 C129.861383,17.311117 128.5425,17.8506383 127.531436,18.9296809 C126.520851,20.0096809 126.015798,21.4324468 126.015798,23.1960638 L126.015798,38.615266 L121.544043,38.615266 L121.544043,23.1960638 C121.544043,21.4324468 121.029894,20.0096809 120.002074,18.9296809 C118.973777,17.8506383 117.645798,17.311117 116.018138,17.311117 C114.373723,17.311117 113.050053,17.8506383 112.048085,18.9296809 C111.045638,20.0096809 110.544894,21.4324468 110.544894,23.1960638 L110.544894,38.615266 L106.047766,38.615266"
            id="Fill-10"
            fill="#FFFFFE"
          ></path>
          <path
            d="M168.062553,27.4624468 C167.428245,31.0605319 165.971489,33.9046277 163.693723,35.9937766 C161.415,38.0848404 158.638883,39.1294149 155.367287,39.1294149 C154.253298,39.1294149 153.152234,38.9790957 152.065053,38.6794149 C150.976436,38.3802128 149.951968,37.9388298 148.994043,37.3562234 C146.970957,36.1230319 145.404096,34.5126064 144.290106,32.5249468 C143.176596,30.537766 142.61984,28.3528723 142.61984,25.9717021 C142.61984,24.223883 142.942021,22.5627128 143.583511,20.9857979 C144.225957,19.4098404 145.15516,17.9885106 146.372074,16.7203723 C147.588032,15.4699468 148.971064,14.5182447 150.522128,13.8671809 C152.072713,13.2165957 153.704202,12.8905851 155.418032,12.8905851 C157.971064,12.8905851 160.254574,13.5631915 162.267128,14.9079255 C164.279681,16.2531383 165.912128,18.2101596 167.162553,20.7809043 L150.638457,29.9288298 L148.736489,26.5117021 L160.99516,19.6750532 C160.240691,18.8363298 159.392394,18.2025 158.451223,17.7735638 C157.509096,17.3460638 156.480798,17.131117 155.367287,17.131117 C153.105319,17.131117 151.199521,17.9746277 149.648936,19.6626064 C148.09883,21.3501064 147.323298,23.4359043 147.323298,25.92 C147.323298,28.3868617 148.10266,30.4688298 149.661383,32.1649468 C151.220585,33.8610638 153.122553,34.7093617 155.367287,34.7093617 C157.509096,34.7093617 159.302394,34.0022872 160.751489,32.588617 C162.198191,31.1754255 163.102021,29.2351596 163.462021,26.7682979 L168.062553,27.4624468"
            id="Fill-11"
            fill="#FFFFFE"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
