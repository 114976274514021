import React, { useRef } from 'react';
import Slider from 'react-slick';

import imgEight from '../../assets/careers/Gallery/imgEight.jpg';
import imgEleven from '../../assets/careers/Gallery/imgEleven.jpg';
import imgFive from '../../assets/careers/Gallery/imgFive.jpg';
import imgFour from '../../assets/careers/Gallery/imgFour.jpg';
import imgNine from '../../assets/careers/Gallery/imgNine.jpg';
import imgOne from '../../assets/careers/Gallery/imgOne.jpg';
import imgSeven from '../../assets/careers/Gallery/imgSeven.jpg';
import imgSix from '../../assets/careers/Gallery/imgSix.jpg';
import imgTen from '../../assets/careers/Gallery/imgTen.jpg';
import imgThree from '../../assets/careers/Gallery/imgThree.jpg';
import imgTwo from '../../assets/careers/Gallery/imgTwo.jpg';
import RightArrow from '../../assets/careers/SvgComponents/RightArrow';
// import imgTwelve from '../../assets/careers/Gallery/imgTwelve.jpg';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import * as styles from './index.module.scss';

type Images = {
  image: string;
  label: string;
};

const galleryList: Images[] = [
  { image: imgOne, label: 'Group photo at Zaanse Schans (2023)' },
  { image: imgTwo, label: 'Amsterdam Canals (2022)' },
  { image: imgEleven, label: 'Shahar & Ronny at JSNation (2023)' },
  { image: imgThree, label: 'Taking the ferry for JSNation (2023)' },
  { image: imgFour, label: 'The team having a beer' },
  { image: imgFive, label: 'Stylish afternoon in Zaanse Schans (2023)' },
  {
    image: imgSix,
    label: 'Nofar & Omri posing at JSNation (2023)',
  },
  { image: imgSeven, label: 'Another sunny day at Amsterdam (2023)' },
  { image: imgEight, label: 'Hummusing.' },
  {
    image: imgNine,
    label: 'Friday dinner to finish off a great trip at Amsterdam (2023)',
  },
  { image: imgTen, label: 'With Ran Bar-Zik & his book' },
  // { image: imgTwelve, label: 'Roaming the streets of Paris' },
];

function Gallery() {
  const sliderRef = useRef<Slider>(null);

  const handleClick = () => {
    sliderRef.current.slickNext();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    variableWidth: true,
    touchThreshold: 600,
    swipeToSlide: true,
  };

  return (
    <section className={styles.background}>
      <div className={styles.galleryContainer}>
        <div className={styles.textContainer}>
          <h2 className={styles.title}>The Really Good Life</h2>
          <p className={styles.text}>
            Here’s a sneak peek into our lives. Join us for our next adventures!
          </p>
          <button
            onClick={() => handleClick()}
            className={styles.arrow}
            type="button"
          >
            <RightArrow />
          </button>
        </div>
        <div className={styles.carousel}>
          <Slider {...settings} ref={sliderRef}>
            {galleryList.map((image, index) => (
              <div key={index}>
                <img
                  src={image.image}
                  alt={image.label}
                  title={image.label}
                  className={styles.image}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default Gallery;
