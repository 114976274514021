import React from 'react';

export const Instagram = (props) => (
  <svg
    {...props}
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.67453 0.119141C4.79893 0.119141 0.834534 4.08354 0.834534 8.95914V21.1991C0.834534 26.0747 4.79893 30.0391 9.67453 30.0391H21.9145C26.7901 30.0391 30.7545 26.0747 30.7545 21.1991V8.95914C30.7545 4.08354 26.7901 0.119141 21.9145 0.119141H9.67453ZM23.9545 5.55914C24.7025 5.55914 25.3145 6.17114 25.3145 6.91914C25.3145 7.66714 24.7025 8.27914 23.9545 8.27914C23.2065 8.27914 22.5945 7.66714 22.5945 6.91914C22.5945 6.17114 23.2065 5.55914 23.9545 5.55914ZM15.7945 7.59914C19.9221 7.59914 23.2745 10.9515 23.2745 15.0791C23.2745 19.2067 19.9221 22.5591 15.7945 22.5591C11.6669 22.5591 8.31453 19.2067 8.31453 15.0791C8.31453 10.9515 11.6669 7.59914 15.7945 7.59914ZM15.7945 8.95914C12.4217 8.95914 9.67453 11.7063 9.67453 15.0791C9.67453 18.4519 12.4217 21.1991 15.7945 21.1991C19.1673 21.1991 21.9145 18.4519 21.9145 15.0791C21.9145 11.7063 19.1673 8.95914 15.7945 8.95914Z"
      fill="#1E1D1D"
    />
  </svg>
);
