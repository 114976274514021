import React from 'react';

function RightArrow(props) {
  return (
    <svg
      {...props}
      width="56"
      height="50"
      viewBox="0 0 56 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="54.5879"
        height="49.6758"
        transform="translate(0.566406)"
        fill="currentColor"
      />
      <path
        d="M30.3164 16L28.4414 17.875L34.1543 23.5879H16.5664V26.0879H34.1543L28.4414 31.8008L30.3164 33.6758L39.1543 24.8379L30.3164 16Z"
        fill="white"
      />
    </svg>
  );
}

export default RightArrow;
