import React from 'react';

export default function VueIcon(props) {
  return (
    <svg
      {...props}
      width="97px"
      height="82px"
      viewBox="0 0 97 82"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>vuejs-icon</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Artboard"
          transform="translate(-347.000000, -508.000000)"
          fillRule="nonzero"
        >
          <g id="Polygon-5-(1)" transform="translate(314.000000, 457.000000)">
            <g id="vuejs-icon" transform="translate(33.238636, 51.000000)">
              <polygon
                id="path4"
                fill="#42B883"
                points="59.1467754 0 48.2613636 18.960956 37.3759475 0 0.761363636 0 48.2613636 82 95.7613636 0"
              ></polygon>
              <polygon
                id="path6"
                fill="#35495E"
                points="60.1657032 0 49.2613614 19.2499969 38.3570151 0 20.7613636 0 49.2613614 50 77.7613636 0"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
