import React from 'react';

export default function ReactIcon(props) {
  return (
    <svg
      {...props}
      width="101px"
      height="89px"
      viewBox="0 0 101 89"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>reactjs-icon</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Artboard" transform="translate(-526.000000, -504.000000)">
          <g id="Polygon-5-(2)" transform="translate(496.000000, 456.000000)">
            <g id="reactjs-icon" transform="translate(32.000000, 50.000000)">
              <circle
                id="Oval"
                fill="#00D8FF"
                fillRule="nonzero"
                cx="49"
                cy="43"
                r="9"
              ></circle>
              <g id="Group" stroke="#00D8FF" strokeWidth="2.73408">
                <path
                  d="M48.5334846,24.2147348 C60.662084,24.2147348 71.9292423,25.9403034 80.4246279,28.8401156 C90.660362,32.3341018 96.953707,37.6300707 96.953707,42.425598 C96.953707,47.4228732 90.2840217,53.0491372 79.2921859,56.6600657 C70.9820894,59.3899098 60.0466146,60.8149991 48.5334846,60.8149991 C36.7293661,60.8149991 25.551521,59.4773934 17.1477896,56.6300713 C6.51554783,53.02682 0.1116416,47.3277125 0.1116416,42.425598 C0.1116416,37.668992 6.12048062,32.4139082 16.2123411,28.9252781 C24.7394184,25.9776178 36.2847805,24.2147348 48.531864,24.2147348 L48.5334846,24.2147348 Z"
                  id="Path"
                ></path>
                <path
                  d="M32.5446068,33.3726566 C38.6038646,22.9553287 45.7399237,14.1398426 52.5176492,8.29165597 C60.683692,1.24548021 68.4548477,-1.51400118 72.6444689,0.881441487 C77.0107358,3.37793659 78.5942459,11.9175817 76.2578753,23.1622541 C74.4932177,31.6638706 70.2735254,41.7680431 64.5218222,51.6568988 C58.6246248,61.7957077 51.8721086,70.7283146 45.1867575,76.5222257 C36.7272053,83.8544191 28.5485578,86.507849 24.2656619,84.058845 C20.1097133,81.6842913 18.5204411,73.8964692 20.514144,63.4853901 C22.1986717,54.688829 26.4261069,43.8917511 32.5426261,33.372478 L32.5446068,33.3726566 Z"
                  id="Path"
                ></path>
                <path
                  d="M32.5597324,51.683501 C26.4833683,41.2779566 22.3463267,30.7433293 20.6194833,22.0006867 C18.5407887,11.4669521 20.0104963,3.41310855 24.1963361,1.01070291 C28.5582814,-1.49311224 36.8109365,1.4113419 45.4694629,9.03419499 C52.0159822,14.7973976 58.7382471,23.4666612 64.5066965,33.346233 C70.4208203,43.4752222 74.8533519,53.7370436 76.5776744,62.3732777 C78.7595475,73.3021166 76.9936294,81.6525115 72.714695,84.1084785 C68.562888,86.4916021 60.965317,83.9685048 52.8640983,77.0562325 C46.0192077,71.2162586 38.696059,62.1924188 32.5597324,51.6833225 L32.5597324,51.683501 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
