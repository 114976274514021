import cc from 'classcat';
import React from 'react';

import { Melio } from '../../assets/careers/SvgComponents/Melio';
import { Promo } from '../../assets/careers/SvgComponents/Promo';
import { Singular } from '../../assets/careers/SvgComponents/Singular';
import { Tailormed } from '../../assets/careers/SvgComponents/Tailormed';
import { Via } from '../../assets/careers/SvgComponents/Via';
import { Walkme } from '../../assets/careers/SvgComponents/Walkme';
import { Watchful } from '../../assets/careers/SvgComponents/Watchful';

import * as styles from './index.module.scss';

export function WorkingOn() {
  return (
    <section className={styles.workingOn}>
      <div className={styles.header}>
        <h2>What are we working on?</h2>
        <h3>
          We love working with clients who appreciate our keen eye for detail.
        </h3>
      </div>
      <p>
        From building rich custom interfaces, to creating design systems &amp;
        UI libraries, we love to tackle the most complex, fun and interesting
        challenges our clients face.{' '}
        <strong>
          Quality &amp; Work/Life balance are very important to us, so we pick
          clients who share our values and outlook.
        </strong>
      </p>
      <div className={styles.logosContainer}>
        <Walkme className={cc([styles.logo, styles.walkme])} />
        <Promo className={styles.logo} />
        <Melio className={styles.logo} />
        <Singular className={styles.logo} />
        <Watchful className={styles.logo} />
        <Via className={styles.logo} />
        <Tailormed className={cc([styles.logo, styles.tailormed])} />
      </div>
    </section>
  );
}
