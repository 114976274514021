import { useEffect } from 'react';

export const usePageTrack = () => {
  useEffect(() => {
    const { analytics, document } = window;

    requestAnimationFrame(() => {
      const { title } = document;
      analytics.page(title, {
        title,
        name: title,
      });
    });
  }, []);
};
