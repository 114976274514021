import React from 'react';

export const Location = (props) => (
  <svg
    {...props}
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.79459 0.169922C4.10921 0.169922 0.294586 3.98455 0.294586 8.66992C0.294586 10.7733 1.06624 12.7023 2.33365 14.1855L2.33756 14.1895L2.34048 14.1934C2.34048 14.1934 5.95645 18.3194 7.2438 19.5479C8.10587 20.3698 9.48233 20.3698 10.3444 19.5479C11.8118 18.1483 15.2497 14.1914 15.2497 14.1914L15.2516 14.1885L15.2545 14.1855C16.5231 12.7023 17.2946 10.7733 17.2946 8.66992C17.2946 3.98455 13.48 0.169922 8.79459 0.169922ZM8.79459 1.66992C12.6692 1.66992 15.7946 4.7953 15.7946 8.66992C15.7946 10.4075 15.1614 11.9862 14.1139 13.2109C14.1071 13.2187 10.5896 17.2417 9.30923 18.4629C9.0123 18.746 8.5759 18.746 8.27896 18.4629C7.20888 17.4418 3.48514 13.2232 3.47427 13.2109L3.4733 13.21C2.42725 11.9853 1.79459 10.407 1.79459 8.66992C1.79459 4.7953 4.91996 1.66992 8.79459 1.66992ZM8.79459 5.66992C7.85709 5.66992 7.05899 6.04849 6.54654 6.625C6.03408 7.20151 5.79459 7.94076 5.79459 8.66992C5.79459 9.39909 6.03408 10.1383 6.54654 10.7148C7.05899 11.2914 7.85709 11.6699 8.79459 11.6699C9.73209 11.6699 10.5302 11.2914 11.0426 10.7148C11.5551 10.1383 11.7946 9.39909 11.7946 8.66992C11.7946 7.94076 11.5551 7.20151 11.0426 6.625C10.5302 6.04849 9.73209 5.66992 8.79459 5.66992ZM8.79459 7.16992C9.35709 7.16992 9.68399 7.35386 9.92154 7.62109C10.1591 7.88833 10.2946 8.27409 10.2946 8.66992C10.2946 9.06576 10.1591 9.45151 9.92154 9.71875C9.68399 9.98599 9.35709 10.1699 8.79459 10.1699C8.23209 10.1699 7.90518 9.98599 7.66763 9.71875C7.43009 9.45151 7.29459 9.06576 7.29459 8.66992C7.29459 8.27409 7.43009 7.88833 7.66763 7.62109C7.90518 7.35386 8.23209 7.16992 8.79459 7.16992Z"
      fill="#333333"
    />
  </svg>
);
