import React from 'react';

import ReviewBorder from '../../assets/careers/SvgComponents/ReviewBorder';
import ReviewFill from '../../assets/careers/SvgComponents/ReviewFill';

import * as styles from './index.module.scss';

interface props {
  text: string;
  avatar: string;
}

function ReviewBox({ text, avatar }: props) {
  return (
    <div className={styles.reviewBox}>
      <div className={styles.reviewWrapper}>
        <img src={avatar} alt="Avatar Icon" className={styles.avatar} />
        <blockquote unselectable="on" className={styles.text}>
          {text}
        </blockquote>
      </div>
      <ReviewBorder className={styles.border} />
      <ReviewFill className={styles.fill} />
    </div>
  );
}

export default ReviewBox;
