import React from 'react';

export const Promo = (props) => (
  <svg
    {...props}
    width="180px"
    height="50px"
    viewBox="0 0 180 50"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Promo Logo</title>
    <defs>
      <linearGradient
        x1="0.00537998663%"
        y1="49.9970053%"
        x2="100.036289%"
        y2="49.9970053%"
        id="linearGradient-1"
      >
        <stop stopColor="#FF3176" offset="0%"></stop>
        <stop stopColor="#FF7369" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="48.5798817%"
        y1="54.2967493%"
        x2="55.1676529%"
        y2="34.4061796%"
        id="linearGradient-2"
      >
        <stop stopColor="#FFFFFF" offset="0%"></stop>
        <stop stopColor="#000000" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Clientelle"
        transform="translate(-61.000000, -195.000000)"
        fillRule="nonzero"
      >
        <g id="logo" transform="translate(61.000000, 195.000000)">
          <path
            d="M57.1521226,15.4015915 C57.1579627,14.2771426 58.0714309,13.3687003 59.1962263,13.3687003 L66.4025943,13.3687003 C71.1573114,13.3687003 74.6808963,17.0185677 74.6808963,21.5830238 C74.6808963,26.1474801 71.1551886,29.7973474 66.368632,29.7973474 L61.5290094,29.7973474 L61.5290094,34.1644562 C61.5290101,35.3578522 60.5619076,36.3256194 59.3681603,36.3267905 L59.3150943,36.3267905 C58.1205181,36.3267905 57.1521226,35.3586805 57.1521226,34.1644562 L57.1521226,15.4015915 Z M61.5290094,17.4175067 L61.5290094,25.727321 L66.0884434,25.727321 C68.5761792,25.727321 70.2742925,23.8705571 70.2742925,21.5724138 C70.2742925,19.2742706 68.5761792,17.4175067 66.0884434,17.4175067 L61.5290094,17.4175067 Z"
            id="Shape"
            fill="#000000"
          ></path>
          <path
            d="M79.1214623,15.4015915 C79.1284652,14.2768003 80.042544,13.3687003 81.1676886,13.3687003 L87.8370283,13.3687003 C93.3558963,13.3687003 96.4952831,16.6111406 96.4952831,21.4323607 C96.4952831,24.1697613 95.1728774,26.7501327 92.6745283,28.1039788 L95.4488208,33.1480106 C95.8176189,33.8176303 95.8049651,34.6322397 95.4155467,35.2900887 C95.0261285,35.9479376 94.3179365,36.351061 93.5533019,36.3501342 C92.7436256,36.3496609 92.0021535,35.8967182 91.6323114,35.1766578 L88.6775943,29.4259947 L83.4983491,29.4259947 L83.4983491,34.1644562 C83.4983496,35.3578522 82.5312473,36.3256194 81.3375,36.3267905 L81.284434,36.3267905 C80.0898576,36.3267905 79.1214623,35.3586805 79.1214623,34.1644562 L79.1214623,15.4015915 Z M83.4983491,17.4493369 L83.4983491,25.3814324 L87.4528302,25.3814324 C91.0103774,25.3814324 92.1226415,23.5246685 92.1226415,21.2265251 C92.1226415,18.928382 90.7386792,17.4493369 87.4528302,17.4493369 L83.4983491,17.4493369 Z"
            id="Shape"
            fill="#000000"
          ></path>
          <path
            d="M100.613097,24.8445624 C100.63899,18.3558522 105.918676,13.1150589 112.409318,13.1352894 C118.899961,13.1556373 124.14676,18.4293354 124.131983,24.91808 C124.117144,31.4068244 118.846328,36.6565337 112.35566,36.6472272 C109.231352,36.6438603 106.236904,35.3972545 104.033665,33.1827202 C101.830426,30.9681858 100.599666,27.9679228 100.613097,24.8445624 Z M119.784906,24.8445624 C119.741651,20.7633508 116.406218,17.4850163 112.323657,17.5110418 C108.241096,17.5370672 104.947754,20.8576587 104.956548,24.9390898 C104.965377,29.0205209 108.273025,32.3268702 112.35566,32.3352785 C114.334625,32.3279814 116.229477,31.5343373 117.622642,30.1292446 C119.015806,28.7241517 119.792939,26.8229317 119.782783,24.8445624 L119.784906,24.8445624 Z"
            id="Shape"
            fill="#000000"
          ></path>
          <path
            d="M132.1875,13.3686569 C132.879721,13.3665802 133.530971,13.6964499 133.938679,14.2557029 L140.187736,22.9177719 L146.511085,14.4530504 C147.033323,13.7655533 147.84876,13.3638507 148.712264,13.3686569 C149.440734,13.3686569 150.139367,13.6579984 150.654473,14.1729524 C151.169579,14.6879064 151.458962,15.3863339 151.458962,16.1145889 L151.458962,34.1644562 C151.458962,35.3586805 150.490567,36.3267905 149.295991,36.3267905 L149.242925,36.3267905 C148.049177,36.3256194 147.082075,35.3578522 147.082075,34.1644562 L147.082075,20.4074271 L141.601415,27.7984085 C141.263299,28.2566236 140.727586,28.5270613 140.158019,28.5270613 C139.588451,28.5270613 139.052738,28.2566236 138.714623,27.7984085 L133.325236,20.4986737 L133.325236,34.1644562 C133.325236,35.3578522 132.358134,36.3256194 131.164387,36.3267905 L131.111321,36.3267905 C130.537665,36.3267905 129.987505,36.0989738 129.581869,35.6934574 C129.176233,35.2879411 128.948349,34.7379429 128.948349,34.1644562 L128.948349,16.5941645 C128.955363,14.8107322 130.403528,13.3686569 132.1875,13.3686569 Z"
            id="Path"
            fill="#000000"
          ></path>
          <path
            d="M156.281493,24.8445624 C156.308554,18.3575819 161.58737,13.1189163 168.076286,13.1395305 C174.565203,13.1602667 179.81051,18.4324647 179.796125,24.9194851 C179.781683,31.4065054 174.512999,36.655357 168.024057,36.6472243 C164.899747,36.6438603 161.9053,35.3972545 159.702061,33.1827202 C157.498822,30.9681858 156.268063,27.9679228 156.281493,24.8445624 Z M175.45339,24.8445624 C175.410048,20.7633508 172.074614,17.4850163 167.992053,17.5110418 C163.909493,17.5370672 160.616151,20.8576587 160.624944,24.9390898 C160.633774,29.0205209 163.941421,32.3268702 168.024057,32.3352785 C170.003214,32.3279798 171.898284,31.5344039 173.291785,30.1293728 C174.685286,28.7243417 175.462894,26.8231277 175.45339,24.8445624 Z"
            id="Shape"
            fill="#000000"
          ></path>
          <path
            d="M40.0882075,20.9485411 L7.19363208,0.876392573 C5.79438005,0.0208024821 4.04188664,-0.012151101 2.61144002,0.790229826 C1.1809934,1.59261075 0.296104011,3.10507605 0.297169811,4.74482759 L0.297169811,27.9278514 C0.261609201,29.5709637 1.11811804,31.1047068 2.5358834,31.9366971 C3.95364878,32.7686872 5.71073802,32.7686872 7.12850339,31.9366971 C8.54626876,31.1047068 9.40277759,29.5709637 9.36721698,27.9278514 L9.36721698,12.8233422 L14.7290094,16.0954908 L25.4886792,22.661008 L25.5459906,22.6970822 L29.1035377,24.8679045 L20.5386792,30.2344827 L16.8431603,32.5474801 C15.4033858,33.375067 14.5311268,34.9230873 14.5693,36.5829587 C14.6074731,38.2428299 15.5499745,39.7491398 17.0262728,40.5097034 C18.5025712,41.2702669 20.2766073,41.1634705 21.6509434,40.2312997 L40.1179246,28.657825 C41.448913,27.8240457 42.2534188,26.3609867 42.2444158,24.7907664 C42.2352607,23.2205462 41.4138903,21.7668825 40.0733491,20.9485411 L40.0882075,20.9485411 Z M4.96698114,39.9702918 C3.04282598,39.9702918 1.3081755,41.1291469 0.572031655,42.9063927 C-0.164112191,44.6836385 0.243252012,46.7292034 1.60413877,48.0890819 C2.96502553,49.4489605 5.01137511,49.8552918 6.78881613,49.1185716 C8.56625713,48.3818516 9.72467936,46.6471954 9.72382123,44.7236074 C9.72031042,42.0990503 7.59231332,39.9726296 4.96698114,39.9702918 Z"
            id="Shape"
            fill="url(#linearGradient-1)"
          ></path>
          <polygon
            id="Path"
            fill="url(#linearGradient-2)"
            points="25.4886792 22.661008 14.7290094 16.0954908 14.7268868 16.0679045"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);
