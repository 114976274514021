import React from 'react';

export const Twitter = (props) => (
  <svg
    {...props}
    width="35"
    height="29"
    viewBox="0 0 35 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.837 4.17674C33.5806 4.73455 32.2339 5.10908 30.8181 5.27908C32.2631 4.41314 33.3735 3.04252 33.8941 1.41158C32.5447 2.21111 31.0439 2.79283 29.4528 3.10627C28.1778 1.74627 26.3609 0.898926 24.3502 0.898926C20.488 0.898926 17.3563 4.02799 17.3563 7.89018C17.3563 8.43736 17.42 8.97127 17.5395 9.48393C11.7277 9.19174 6.5772 6.40799 3.12673 2.17658C2.52376 3.21252 2.18111 4.41314 2.18111 5.69346C2.18111 8.11861 3.41361 10.2596 5.29157 11.516C4.14407 11.4788 3.06564 11.1627 2.12267 10.6394C2.12267 10.6686 2.12267 10.6978 2.12267 10.7271C2.12267 14.1164 4.53454 16.94 7.73001 17.5855C7.14564 17.7449 6.52673 17.8299 5.88923 17.8299C5.43767 17.8299 4.99939 17.7874 4.57173 17.7024C5.46423 20.4781 8.04611 22.5049 11.1034 22.5606C8.71282 24.436 5.69798 25.5516 2.42017 25.5516C1.85439 25.5516 1.29923 25.5197 0.7547 25.4533C3.84657 27.4402 7.52282 28.5956 11.4727 28.5956C24.3342 28.5956 31.368 17.9414 31.368 8.70299C31.368 8.40018 31.36 8.09471 31.3494 7.79721C32.7147 6.81174 33.8994 5.57924 34.837 4.17674Z"
      fill="#1E1D1D"
    />
  </svg>
);
