// extracted by mini-css-extract-plugin
export var requirements = "index-module--requirements--X8Zry";
export var imagesWrapper = "index-module--imagesWrapper--27KkF";
export var hexagonImg = "index-module--hexagonImg--1XFnb";
export var hexagonShadow = "index-module--hexagonShadow--txreA";
export var triangleLines = "index-module--triangleLines--76h10";
export var bulbIcon = "index-module--bulbIcon--3FuMo";
export var msgPopIcon = "index-module--msgPopIcon--3O3Ul";
export var backgroundImage = "index-module--backgroundImage--2X30z";
export var img = "index-module--img--u6ON5";
export var textContainer = "index-module--textContainer--3lqiZ";
export var leadingParagraph = "index-module--leadingParagraph--1QxCC";
export var concludeText = "index-module--concludeText--2n-fE";
export var checkIcon = "index-module--checkIcon--2VimE";
export var title = "index-module--title--7dxmN";
export var listText = "index-module--listText--2aNIT";
export var mainParagraph = "index-module--mainParagraph--1_1vS";