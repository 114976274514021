// extracted by mini-css-extract-plugin
export var hexagons = "index-module--hexagons--2HBqd";
export var hexContainer = "index-module--hexContainer--1N-5l";
export var hexagonImg = "index-module--hexagonImg--28huj";
export var smallHex = "index-module--smallHex--34Fp9";
export var mediumHex = "index-module--mediumHex--2f-o0";
export var largeHex = "index-module--largeHex--OsPUk";
export var icon = "index-module--icon--2hRYH";
export var react = "index-module--react--k8Ndr";
export var angular = "index-module--angular--1RNQc";
export var js = "index-module--js--2nd2B";
export var vue = "index-module--vue--2zIAb";
export var grey = "index-module--grey--1HT3a";
export var border = "index-module--border--K0lO5";