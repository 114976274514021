import React from 'react';

function MsgPop(props) {
  return (
    <svg
      {...props}
      width="138"
      height="61"
      viewBox="0 0 138 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.89113 30.42L13.9712 36.9653V23.8845L0.89113 30.42Z"
        fill="white"
      />
      <path
        d="M13.9712 58.7763H129.885C132.507 58.7763 134.624 56.6496 134.624 54.0373V4.85076C134.624 2.22877 132.497 0.111755 129.885 0.111755H13.9712V58.7763Z"
        fill="white"
      />
      <path
        d="M40.7531 19.3586H117.213C118.554 19.3586 119.641 18.271 119.641 16.9309C119.641 15.5908 118.554 14.5031 117.213 14.5031H40.7531C39.413 14.5031 38.3254 15.5908 38.3254 16.9309C38.3254 18.271 39.413 19.3586 40.7531 19.3586Z"
        fill="#C3262A"
      />
      <path
        d="M81.3429 33.7794H117.213C118.554 33.7794 119.641 32.6918 119.641 31.3517C119.641 30.0115 118.554 28.9239 117.213 28.9239H81.3429C80.0029 28.9239 78.9153 30.0115 78.9153 31.3517C78.9153 32.6918 79.9932 33.7794 81.3429 33.7794Z"
        fill="#C3262A"
      />
      <path
        d="M34.8988 33.7794H70.7694C72.1094 33.7794 73.197 32.6918 73.197 31.3517C73.197 30.0115 72.1094 28.9239 70.7694 28.9239H34.8988C33.5588 28.9239 32.4712 30.0115 32.4712 31.3517C32.4712 32.6918 33.5588 33.7794 34.8988 33.7794Z"
        fill="#C3262A"
      />
      <path
        d="M54.2798 47.0742H117.213C118.554 47.0742 119.641 45.9865 119.641 44.6464C119.641 43.3063 118.554 42.2186 117.213 42.2186H54.2798C52.9397 42.2186 51.8522 43.3063 51.8522 44.6464C51.8522 45.9865 52.9397 47.0742 54.2798 47.0742Z"
        fill="#C3262A"
      />
      <mask id="path-7-inside-1_547:3651" fill="white">
        <path d="M16.9712 60.7763H132.885C135.507 60.7763 137.624 58.6496 137.624 56.0373V6.85076C137.624 4.22877 135.497 2.11176 132.885 2.11176H16.9712V60.7763Z" />
      </mask>
      <path
        d="M16.9712 60.7763V63.7763H13.9712V60.7763H16.9712ZM16.9712 2.11176H13.9712V-0.888245H16.9712V2.11176ZM16.9712 57.7763H132.885V63.7763H16.9712V57.7763ZM132.885 57.7763C133.846 57.7763 134.624 56.9969 134.624 56.0373H140.624C140.624 60.3022 137.168 63.7763 132.885 63.7763V57.7763ZM134.624 56.0373V6.85076H140.624V56.0373H134.624ZM134.624 6.85076C134.624 5.88946 133.844 5.11176 132.885 5.11176V-0.888245C137.15 -0.888245 140.624 2.56808 140.624 6.85076H134.624ZM132.885 5.11176H16.9712V-0.888245H132.885V5.11176ZM19.9712 2.11176V60.7763H13.9712V2.11176H19.9712Z"
        fill="black"
        mask="url(#path-7-inside-1_547:3651)"
      />
    </svg>
  );
}

export default MsgPop;
