import React from 'react';

export const Singular = (props) => (
  <svg
    {...props}
    width="180px"
    height="36px"
    viewBox="0 0 180 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Singular Logo</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Clientelle"
        transform="translate(-331.000000, -86.000000)"
        fill="#3A92E5"
        fillRule="nonzero"
      >
        <g
          id="608b052f0b21fe3e278f0a80291954ac7905fc32"
          transform="translate(331.000000, 86.000000)"
        >
          <path
            d="M24.156,7.524 C26.3620438,10.6891646 27.5203532,14.4662603 27.468,18.324 C27.468,18.3935878 27.5244122,18.45 27.594,18.45 C27.6635878,18.45 27.72,18.3935878 27.72,18.324 C28.026,17.82 28.278,17.316 28.53,16.794 C30.3402566,12.8123804 30.4636054,8.26795586 28.872,4.194 C25.2765335,1.31042392 20.6639383,0.0111013006 16.092,0.594 C15.948,0.594 15.912,0.81 16.092,0.864 C19.379921,2.23694426 22.1864114,4.55480464 24.156,7.524 Z"
            id="Path"
          ></path>
          <path
            d="M30.33,17.658 C28.6850596,21.3419929 25.8691552,24.3793728 22.32,26.298 C22.2635605,26.3189495 22.2261098,26.3727979 22.2261098,26.433 C22.2261098,26.4932021 22.2635605,26.5470505 22.32,26.568 C23.1697256,26.5646882 24.0180415,26.4985074 24.858,26.37 C28.9171496,25.716791 32.6012526,23.6125028 35.226,20.448 C35.9309945,15.8545892 34.7279843,11.1726038 31.896,7.488 C31.8549452,7.44081872 31.7881294,7.42553228 31.7306439,7.45016897 C31.6731582,7.47480566 31.6381478,7.5337319 31.644,7.596 C32.168891,11.0067447 31.7131718,14.4964299 30.33,17.658 Z"
            id="Path"
          ></path>
          <path
            d="M12.6,7.452 C16.2712248,7.20175333 19.928578,8.10822298 23.058,10.044 C23.1109945,10.0698767 23.1744109,10.0608242 23.2180486,10.0211535 C23.2616864,9.98148276 23.2767239,9.9192136 23.256,9.864 C23.022,9.45 22.77,9.036 22.5,8.64 C20.0105768,4.99650064 16.1425822,2.5261848 11.79,1.8 C7.49241279,3.50923826 4.05719525,6.8666773 2.25,11.124 C2.23289586,11.1817213 2.2535042,11.2439908 2.30166592,11.2801121 C2.34982764,11.3162334 2.41537673,11.3185819 2.466,11.286 C5.40151985,9.05373747 8.92202924,7.72182185 12.6,7.452 Z"
            id="Path"
          ></path>
          <path
            d="M25.2,28.314 C24.2587859,28.4574623 23.3080846,28.5296675 22.356,28.53 C18.9825206,28.5190677 15.6833718,27.5380709 12.852,25.704 C12.7990055,25.6781234 12.7355892,25.6871758 12.6919514,25.7268465 C12.6483136,25.7665172 12.6332761,25.8287864 12.654,25.884 C12.9699132,26.4537709 13.3247556,27.0010701 13.716,27.522 C16.3539968,30.9975147 20.2149302,33.3398144 24.516,34.074 C28.8759272,32.390368 32.3690323,29.0141325 34.2,24.714 C34.2171041,24.6562787 34.1964958,24.5940091 34.1483341,24.557888 C34.1001724,24.5217667 34.0346232,24.519418 33.984,24.552 C31.4157676,26.5192915 28.3960336,27.812579 25.2,28.314 Z"
            id="Path"
          ></path>
          <path
            d="M12.222,28.8 C9.82900843,25.6055162 8.61436609,21.6817763 8.784,17.694 C8.75863888,17.6481625 8.71038569,17.6197137 8.658,17.6197137 C8.60561431,17.6197137 8.55736112,17.6481625 8.532,17.694 C8.28,18.108 8.046,18.54 7.83,18.972 C6.02051081,22.9495005 5.87151034,27.4842974 7.416,31.572 C11.065432,34.5006373 15.757387,35.8021505 20.394,35.172 C20.4504395,35.1510505 20.4878902,35.0972021 20.4878902,35.037 C20.4878902,34.9767979 20.4504395,34.9229495 20.394,34.902 C17.1641021,33.6520255 14.3379795,31.5417708 12.222,28.8 Z"
            id="Path"
          ></path>
          <path
            d="M5.868,18.108 C7.45316453,14.4440824 10.2580421,11.4420365 13.806,9.612 C13.8624395,9.59105054 13.8998901,9.53720215 13.8998901,9.477 C13.8998901,9.41679785 13.8624395,9.36294946 13.806,9.342 C13.32,9.342 12.834,9.342 12.348,9.342 C7.97597845,9.74215771 3.94818473,11.8825312 1.17,15.282 C0.43203397,19.902577 1.61563236,24.623964 4.446,28.35 C4.48705474,28.3971812 4.55387053,28.4124677 4.61135615,28.387831 C4.66884178,28.3631944 4.70385212,28.3042681 4.698,28.242 C4.16190643,24.8204007 4.56633529,21.3174247 5.868,18.108 Z"
            id="Path"
          ></path>
          <path
            d="M107.01,10.674 C107.01,10.116 107.01,9.54 107.01,8.982 C107.01,8.424 107.118,8.568 107.442,8.568 L110.826,8.568 C111.168,8.568 111.276,8.568 111.276,9 C111.276,10.35 111.276,11.682 111.276,13.014 L111.276,25.47 C111.348606,27.6974186 110.67027,29.8845747 109.35,31.68 C107.968915,33.4705684 105.975535,34.6883886 103.752,35.1 C99.865165,35.9273155 95.809034,35.1342898 92.52,32.904 L92.25,32.688 L94.05,29.286 L94.536,29.592 C96.6300683,30.9577633 99.1178287,31.5907812 101.61,31.392 C103.652076,31.3418497 105.491067,30.1437189 106.362,28.296 C106.798719,27.2438802 106.959798,26.0977383 106.83,24.966 C106.83,24.966 106.65,24.966 106.614,25.092 C105.132474,26.6127113 103.084457,27.447572 100.962,27.396 C97.4571597,27.5733729 94.1688605,25.697356 92.538,22.59 C90.3097145,18.4819304 91.2212917,13.3785921 94.734,10.296 C97.3367217,8.0355389 100.986451,7.42377478 104.184,8.712 C105.074823,9.07253294 105.878651,9.61864542 106.542,10.314 C106.65,10.422 106.758,10.566 106.848,10.674 L107.01,10.674 Z M95.994,17.874 C95.994,20.9159843 98.4600157,23.382 101.502,23.382 C104.543984,23.382 107.01,20.9159843 107.01,17.874 C107.01,14.8320156 104.543984,12.366 101.502,12.366 C98.4600157,12.366 95.994,14.8320156 95.994,17.874 Z"
            id="Shape"
          ></path>
          <path
            d="M163.602,27.414 L159.318,27.414 L159.318,25.2 C158.94,25.542 158.67,25.794 158.382,26.028 C156.58516,27.5526151 154.209203,28.2107749 151.884,27.828 C149.210184,27.5225625 146.823021,26.0106928 145.404,23.724 C144.074881,21.6336668 143.526646,19.1411123 143.856,16.686 C144.004032,14.9216561 144.625939,13.2300686 145.656,11.79 C148.350098,8.18435875 153.293632,7.12338242 157.23,9.306 C157.914,9.756 158.544,10.314 159.264,10.872 L159.264,9.81 C159.264,8.568 159.264,8.568 160.506,8.568 L163.17,8.568 C163.458,8.568 163.584,8.568 163.584,8.946 C163.584,15.03 163.584,21.1019999 163.584,27.162 C163.59776,27.2452649 163.603786,27.3296246 163.602,27.414 Z M159.444,18 C159.406993,17.522413 159.34087,17.0475301 159.246,16.578 C158.814933,14.687697 157.427685,13.1599205 155.587614,12.5490169 C153.747543,11.9381134 151.722022,12.3328506 150.246,13.59 C148.751704,14.9570632 148.070801,16.9997721 148.446,18.99 C148.724905,21.6849847 150.957686,23.756081 153.666,23.832 C155.224685,23.8889839 156.736076,23.2909976 157.833828,22.1829867 C158.931579,21.0749758 159.515481,19.5580876 159.444,18 Z"
            id="Shape"
          ></path>
          <path
            d="M87.768,27.414 L83.196,27.414 L83.196,17.298 C83.245223,16.3688318 83.1106539,15.4390808 82.8,14.562 C82.2001171,13.3469813 81.0544541,12.4928977 79.7187287,12.2649339 C78.3830032,12.03697 77.0189148,12.4627228 76.05,13.41 C75.099472,14.3145559 74.5652704,15.5718839 74.574,16.884 C74.574,20.214 74.574,23.544 74.574,26.874 L74.574,27.414 L70.074,27.414 C70.0650981,27.258127 70.0650981,27.101873 70.074,26.946 L70.074,9.09 C70.074,8.694 70.074,8.55 70.578,8.568 L74.178,8.568 C74.52,8.568 74.628,8.676 74.61,9.018 C74.61,9.684 74.61,10.35 74.61,11.088 C74.61,10.998 74.754,10.98 74.772,10.926 C75.9302779,9.21876617 77.8396806,8.17361939 79.902,8.118 C81.9562302,7.86964325 84.0180157,8.51708567 85.5615017,9.8951981 C87.1049876,11.2733106 87.9809587,13.2488652 87.966,15.318 C87.966,18.558 87.966,21.816 87.966,25.074 L87.768,27.414 Z"
            id="Path"
          ></path>
          <path
            d="M132.012,8.568 L132.012,19.368 C132.067108,21.0171163 131.6819,22.651144 130.896,24.102 C129.858472,25.9018772 128.080481,27.1530565 126.036,27.522 C123.616907,28.1299514 121.059546,27.8422483 118.836,26.712 C116.731687,25.5854554 115.337379,23.4736868 115.128,21.096 C115.023256,20.2904743 114.98113,19.4780392 115.002,18.666 C115.002,15.444 115.002,12.24 115.002,9.036 C115.002,8.568 115.146,8.478 115.56,8.496 L119.16,8.496 C119.556,8.496 119.664,8.496 119.664,8.982 C119.664,12.438 119.664,15.894 119.664,19.35 C119.632836,20.2395031 119.837782,21.1213955 120.258,21.906 C121.104895,23.2769948 122.703896,23.9908345 124.29,23.706 C126.183534,23.4038612 127.538586,21.7139404 127.422,19.8 C127.422,17.658 127.422,15.498 127.422,13.356 C127.422,11.898 127.422,10.422 127.422,8.964 C127.422,8.82 127.566,8.55 127.656,8.55 L132.012,8.568 Z"
            id="Path"
          ></path>
          <path
            d="M57.6,10.8 L55.242,13.644 L54.846,13.284 C53.7040008,12.1891541 52.1597963,11.6157786 50.58,11.7 C49.776154,11.7209688 49.0648234,12.2260135 48.78,12.978 C48.5615214,13.5899772 48.774404,14.2726697 49.302,14.652 C49.7094293,14.9440044 50.1506212,15.1857535 50.616,15.372 C52.164,16.056 53.748,16.614 55.242,17.37 C57.060844,18.1717783 58.1984323,20.0105217 58.104,21.996 C58.1544803,24.1250137 56.9489602,26.0848652 55.026,27 C51.0523169,28.9961977 46.2281206,28.0060463 43.362,24.606 C43.146,24.336 43.164,24.192 43.362,23.94 C44.136,23.148 44.874,22.32 45.612,21.546 C46.134,22.014 46.584,22.5 47.124,22.896 C48.391427,23.9311399 50.0545573,24.3469226 51.66,24.03 C52.1003338,23.9315206 52.5126467,23.734595 52.866,23.454 C53.295964,23.1709912 53.5548008,22.6907456 53.5548008,22.176 C53.5548008,21.6612544 53.295964,21.1810088 52.866,20.898 C52.3071538,20.5029828 51.702811,20.1766376 51.066,19.926 C49.734,19.332 48.366,18.864 47.07,18.126 C45.2758316,17.3059825 44.1682326,15.4695118 44.28,13.5 C44.2354091,11.5423292 45.3408662,9.73980063 47.106,8.892 C50.4795962,7.20623862 54.5464449,7.80261397 57.294,10.386 L57.6,10.8 Z"
            id="Path"
          ></path>
          <path
            d="M140.13,27.414 L135.63,27.414 L135.63,0.63 L136.044,0.63 L139.644,0.63 C139.968,0.63 140.112,0.63 140.112,1.062 C140.112,9.594 140.112,18.558 140.112,27.09 C140.148,27.144 140.13,27.252 140.13,27.414 Z"
            id="Path"
          ></path>
          <path
            d="M178.722,12.744 C178.127881,12.614292 177.52693,12.5181399 176.922,12.456 C175.724361,12.3205123 174.523033,12.6770695 173.59323,13.443987 C172.663428,14.2109046 172.084834,15.3224584 171.99,16.524 C171.896643,17.1859529 171.848532,17.8535012 171.846,18.522 C171.846,21.276 171.846,24.03 171.846,26.802 L171.846,27.396 L167.256,27.396 L167.256,27 C167.256,21.06 167.256,15.12 167.256,9.18 C167.256,8.73 167.382,8.622 167.814,8.64 L171.594,8.64 L171.594,10.944 L171.702,10.944 C171.702,10.818 171.9,10.692 171.972,10.566 C173.514515,8.37826902 176.314651,7.46932426 178.848,8.334 C178.983552,8.40318998 179.082158,8.52809144 179.118,8.676 C179.01,9.99 178.884,11.304 178.776,12.618 L178.722,12.744 Z"
            id="Path"
          ></path>
          <path
            d="M66.258,27.414 L61.74,27.414 C61.74,27.252 61.74,27.09 61.74,26.946 C61.74,20.9460001 61.74,14.994 61.74,9.09 C61.74,8.694 61.74,8.55 62.244,8.568 L65.844,8.568 C66.168,8.568 66.312,8.568 66.312,9 C66.312,15.03 66.312,21.042 66.312,27 C66.3059999,27.1393015 66.2879322,27.2778201 66.258,27.414 Z"
            id="Path"
          ></path>
          <path
            d="M66.888,3.456 C66.917282,4.63424216 66.230075,5.71285386 65.1498152,6.18418264 C64.0695553,6.65551141 62.8114518,6.42565939 61.9676352,5.60280722 C61.1238186,4.77995506 60.862394,3.528031 61.3064016,2.43625752 C61.7504092,1.34448407 62.811394,0.630363809 63.99,0.63 C64.7518977,0.610582252 65.4893386,0.899726287 66.0349922,1.4318233 C66.5806459,1.96392031 66.8882473,2.69385498 66.888,3.456 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
