import React, { useRef } from 'react';
import Slider from 'react-slick';

import avatarFour from '../../assets/careers/Figures/avatarFour.png';
import avatarOne from '../../assets/careers/Figures/avatarOne.png';
import avatarThree from '../../assets/careers/Figures/avatarThree.png';
import avatarTwo from '../../assets/careers/Figures/avatarTwo.png';
import RightArrow from '../../assets/careers/SvgComponents/RightArrow';
import useMediaQuery from '../../hooks/UseMediaQuery';
import ReviewBox from '../ReviewBox/ReviewBox';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import * as styles from './index.module.scss';

type Review = {
  text: string;
  avatar: string;
  id: number;
};

const reviewList: Review[] = [
  {
    text: '“I think your interview approach should be a standard in our industry. You really take it to a new level of professionalism."',
    avatar: avatarThree,
    id: 3,
  },
  {
    text: '“Thank you for the task, I had a lot of fun doing it :) Learned some new tricks too.”',
    avatar: avatarOne,
    id: 1,
  },
  {
    text: '“I enjoyed working on the task, as it was very different from the more generic ones you often see, and much more interesting and challenging."',
    avatar: avatarTwo,
    id: 2,
  },

  {
    text: '“This was a blast to work on! I got to play with some CSS features that I’ve never used before. Great way to learn!"',
    avatar: avatarFour,
    id: 4,
  },
];

function Reviews() {
  const sliderRef = useRef<Slider>(null);

  const isMatchMedia = useMediaQuery('(max-width: 820px)');

  const handleClick = () => {
    sliderRef.current.slickNext();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMatchMedia ? 1 : 3,
    slidesToScroll: 1,
    touchThreshold: 500,
  };

  return (
    <section className={styles.reviews}>
      <article className={styles.textContainer}>
        <h2 className={styles.title}>Candidates love our process</h2>
        <p className={styles.text}>
          <strong className={styles.bold}>
            Our hiring process isn’t easy, but it’s fair.&nbsp;
          </strong>
          We focus on getting to know you and setting expectations together.
        </p>
        <button
          onClick={() => handleClick()}
          className={styles.arrow}
          type="button"
        >
          <RightArrow />
        </button>
      </article>
      <article className={styles.carousel}>
        <Slider {...settings} ref={sliderRef}>
          {reviewList.map((review) => (
            <div key={review.id}>
              <ReviewBox text={review.text} avatar={review.avatar} />
            </div>
          ))}
        </Slider>
      </article>
    </section>
  );
}

export default Reviews;
