import React from 'react';

export const Whatsapp = (props) => (
  <svg
    {...props}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7947 2.62354C7.28082 2.62354 2.79468 7.10968 2.79468 12.6235C2.79468 14.2612 3.22603 15.7919 3.92554 17.1567L2.84155 21.0376C2.58908 21.9393 3.48064 22.8308 4.38257 22.5786L8.26636 21.4946C9.63005 22.1927 11.1586 22.6235 12.7947 22.6235C18.3085 22.6235 22.7947 18.1374 22.7947 12.6235C22.7947 7.10968 18.3085 2.62354 12.7947 2.62354ZM12.7947 4.12354C17.4978 4.12354 21.2947 7.92039 21.2947 12.6235C21.2947 17.3267 17.4978 21.1235 12.7947 21.1235C11.2939 21.1235 9.88998 20.7333 8.66577 20.0513C8.49321 19.9551 8.28967 19.9309 8.09937 19.9839L4.40601 21.0142L5.43726 17.3228C5.49053 17.1321 5.46631 16.9282 5.36987 16.7554C4.6865 15.5303 4.29468 14.1258 4.29468 12.6235C4.29468 7.92039 8.09153 4.12354 12.7947 4.12354ZM9.41479 8.12451C9.25529 8.12451 8.99758 8.18434 8.77808 8.42334C8.55908 8.66134 7.94214 9.23857 7.94214 10.4136C7.94214 11.5886 8.79725 12.7233 8.91675 12.8833C9.03575 13.0418 10.5683 15.5308 12.9958 16.4878C15.0128 17.2828 15.4231 17.1245 15.8611 17.0845C16.2991 17.0455 17.2744 16.5077 17.4734 15.9497C17.6724 15.3917 17.6725 14.9144 17.614 14.8159C17.5545 14.7164 17.395 14.6561 17.156 14.5366C16.9175 14.4171 15.7441 13.8398 15.5251 13.7603C15.3061 13.6808 15.1461 13.6404 14.9871 13.8794C14.8281 14.1184 14.3707 14.6559 14.2312 14.8149C14.0917 14.9749 13.9526 14.995 13.7136 14.8755C13.4741 14.755 12.7057 14.5034 11.7927 13.6899C11.0827 13.0574 10.6051 12.2756 10.4656 12.0366C10.3266 11.7986 10.4506 11.6678 10.5701 11.5493C10.6776 11.4423 10.809 11.2708 10.9285 11.1313C11.047 10.9918 11.0863 10.8929 11.1658 10.7339C11.2453 10.5754 11.2057 10.4354 11.1462 10.3159C11.0867 10.1964 10.6224 9.01498 10.4099 8.54248C10.2314 8.14548 10.0433 8.13687 9.8728 8.13037C9.7338 8.12487 9.57379 8.12451 9.41479 8.12451Z"
      fill="#333333"
    />
  </svg>
);
