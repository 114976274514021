import React from 'react';

export const Linkedin = (props) => (
  <svg
    {...props}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.6745 0.799316H3.91453C2.03773 0.799316 0.514526 2.32252 0.514526 4.19932V25.9593C0.514526 27.8361 2.03773 29.3593 3.91453 29.3593H25.6745C27.5513 29.3593 29.0745 27.8361 29.0745 25.9593V4.19932C29.0745 2.32252 27.5513 0.799316 25.6745 0.799316ZM9.35453 11.6793V24.5993H5.27453V11.6793H9.35453ZM5.27453 7.91892C5.27453 6.96692 6.09053 6.23932 7.31453 6.23932C8.53853 6.23932 9.30693 6.96692 9.35453 7.91892C9.35453 8.87092 8.59293 9.63932 7.31453 9.63932C6.09053 9.63932 5.27453 8.87092 5.27453 7.91892ZM24.3145 24.5993H20.2345C20.2345 24.5993 20.2345 18.3025 20.2345 17.7993C20.2345 16.4393 19.5545 15.0793 17.8545 15.0521H17.8001C16.1545 15.0521 15.4745 16.4529 15.4745 17.7993C15.4745 18.4181 15.4745 24.5993 15.4745 24.5993H11.3945V11.6793H15.4745V13.4201C15.4745 13.4201 16.7869 11.6793 19.4253 11.6793C22.1249 11.6793 24.3145 13.5357 24.3145 17.2961V24.5993Z"
      fill="#1E1D1D"
    />
  </svg>
);
