import React from 'react';

import { Envelope } from '../../assets/careers/SvgComponents/Envelope';
import { Facebook } from '../../assets/careers/SvgComponents/Facebook';
import { FooterLogo } from '../../assets/careers/SvgComponents/FooterLogo';
import { Github } from '../../assets/careers/SvgComponents/Github';
import { Instagram } from '../../assets/careers/SvgComponents/Instagram';
import { Linkedin } from '../../assets/careers/SvgComponents/Linkedin';
import { Location } from '../../assets/careers/SvgComponents/Location';
import { LogoFull } from '../../assets/careers/SvgComponents/LogoFull';
import { Telegram } from '../../assets/careers/SvgComponents/Telegram';
import { Tiktok } from '../../assets/careers/SvgComponents/Tiktok';
import { Twitter } from '../../assets/careers/SvgComponents/Twitter';
import { Whatsapp } from '../../assets/careers/SvgComponents/Whatsapp';

import * as styles from './index.module.scss';

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer id="footer" className={styles.footer}>
      <nav>
        <div className={styles.logoContainer}>
          <FooterLogo className={styles.logo} />
          <LogoFull className={styles.logoFull} />
        </div>
        <div>
          <h3>About</h3>
          <menu>
            <li>
              <a href="https://reallygood.co.il/">Home</a>
            </li>
            <li>
              <a href="https://reallygood.co.il/#why">Why</a>
            </li>
            <li>
              <a href="https://reallygood.co.il/#clients">Clients</a>
            </li>
            <li>
              <a href="https://reallygood.co.il/#tech">Tech</a>
            </li>
          </menu>
        </div>
        <div>
          <h3>Company</h3>
          <menu>
            <li>
              <a href="https://reallygood.co.il/#team">Team</a>
            </li>
            <li>
              <a href="https://careers.reallygood.co.il/">Careers</a>
            </li>
          </menu>
        </div>
        <div className={styles.contact}>
          <h3>Contact</h3>
          <menu>
            <li>
              <a
                href="mailto:team@reallygood.co.il"
                onClick={() => {
                  analytics.track('External Link Clicked', {
                    name: 'RG Email',
                  });
                }}
              >
                <Envelope className={styles.icon} />
                <address>team@reallygood.co.il</address>
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/972546161614"
                target="_blank"
                onClick={() => {
                  analytics.track('External Link Clicked', {
                    name: 'Shahar WhatsApp',
                  });
                }}
              >
                <Whatsapp className={styles.icon} />

                <address>
                  <strong>Shahar Tal:</strong> +972-54-616-1614
                </address>
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/972549213923"
                target="_blank"
                onClick={() => {
                  analytics.track('External Link Clicked', {
                    name: 'Ronny WhatsApp',
                  });
                }}
              >
                <Whatsapp className={styles.icon} />
                <address>
                  <strong>Ronny Orbach:</strong> +972-54-921-3923
                </address>
              </a>
            </li>
            <li>
              <a
                href="https://www.google.com/maps/place/Really+Good/@32.0825575,34.7992382,17z/data=!3m1!4b1!4m5!3m4!1s0x151d4bc761bb0e0d:0xf7695c45d50e6b02!8m2!3d32.0825575!4d34.8014269"
                target="_blank"
                className={styles.address}
                onClick={() => {
                  analytics.track('External Link Clicked', {
                    name: 'RG Map',
                  });
                }}
              >
                <Location className={styles.icon} />
                <address>
                  Sarona Tower <br />
                  121 Menachem Begin Road
                  <br />
                  <strong>Tel Aviv, Israel</strong>
                </address>
              </a>
            </li>
          </menu>
        </div>
        <div className={styles.followUs}>
          <h3>Follow Us</h3>
          <menu>
            <li>
              <a
                href="https://www.facebook.com/ReallyGoodTeam/"
                target="_blank"
                onClick={() => {
                  analytics.track('External Link Clicked', {
                    name: 'RG Facebook',
                  });
                }}
              >
                <Facebook className={styles.icon} />
              </a>
              <a
                href="https://twitter.com/ReallyGoodTeam"
                target="_blank"
                onClick={() => {
                  analytics.track('External Link Clicked', {
                    name: 'RG Twitter',
                  });
                }}
              >
                <Twitter className={styles.icon} />
              </a>
              <a
                href="https://il.linkedin.com/company/really-good"
                target="_blank"
                onClick={() => {
                  analytics.track('External Link Clicked', {
                    name: 'RG LinkedIn',
                  });
                }}
              >
                <Linkedin className={styles.icon} />
              </a>
              <a
                href="https://github.com/ReallyGood"
                target="_blank"
                onClick={() => {
                  analytics.track('External Link Clicked', {
                    name: 'RG Github',
                  });
                }}
              >
                <Github className={styles.icon} />
              </a>
              <a
                href="https://www.instagram.com/reallygood.il/"
                target="_blank"
                onClick={() => {
                  analytics.track('External Link Clicked', {
                    name: 'RG Instagram',
                  });
                }}
              >
                <Instagram className={styles.icon} />
              </a>
              <a
                href="https://www.tiktok.com/@reallygoodcode"
                target="_blank"
                onClick={() => {
                  analytics.track('External Link Clicked', {
                    name: 'RG Tiktok',
                  });
                }}
              >
                <Tiktok className={styles.icon} />
              </a>
              <a
                href="https://t.me/ReallyGoodFrontEnd"
                target="_blank"
                onClick={() => {
                  analytics.track('External Link Clicked', {
                    name: 'RG Telegram',
                  });
                }}
              >
                <Telegram className={styles.icon} />
              </a>
            </li>
          </menu>
        </div>
      </nav>
      <hr />
      <small>© {currentYear} Really Good Code Ltd.</small>
    </footer>
  );
}
