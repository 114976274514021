import React from 'react';

function ReviewFill(props) {
  return (
    <svg
      {...props}
      width="546"
      height="285"
      viewBox="0 0 546 285"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.544434 0.562012H485.567C518.704 0.562012 545.567 27.4249 545.567 60.562V284.676H60.5444C27.4073 284.676 0.544434 257.813 0.544434 224.676V0.562012Z"
        fill="#F5F5F5"
      />
    </svg>
  );
}

export default ReviewFill;
