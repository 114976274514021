import cc from 'classcat';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';
import useMediaQuery from '../../hooks/UseMediaQuery';

import CloseButton from '../../assets/careers/SvgComponents/CloseButton';
import { Logo } from '../../assets/careers/SvgComponents/Logo';
import MenuIcon from '../../assets/careers/SvgComponents/MenuIcon';

import * as styles from './index.module.scss';

function Navbar() {
  const [isMenuOpen, toggleMenu] = useState(false);

  const isWideScreen = useMediaQuery('(min-width: 769px)');
  if (isMenuOpen && isWideScreen) {
    toggleMenu(false);
  }

  return (
    <nav>
      <BodyClassName
        className={cc({
          [styles.menuIsOpen]: isMenuOpen,
        })}
      />
      <button
        type="button"
        className={styles.menuButton}
        onClick={() => toggleMenu(true)}
      >
        <MenuIcon />
      </button>
      {isMenuOpen && (
        <aside className={styles.popMenu}>
          <button
            type="button"
            className={styles.closeButton}
            onClick={() => toggleMenu(false)}
          >
            <CloseButton />
          </button>
          <menu className={styles.popMenuList}>
            <li>
              <a href="https://reallygood.co.il/" title="Home">
                Home
              </a>
            </li>
            <li>
              <a href="https://reallygood.co.il/#why" title="Why">
                Why
              </a>
            </li>
            <li>
              <a href="https://reallygood.co.il/#clients" title="Clients">
                Clients
              </a>
            </li>
            <li>
              <a href="https://reallygood.co.il/#team" title="Team">
                Team
              </a>
            </li>
            <li>
              <a href="https://reallygood.co.il/#tech" title="Tech">
                Tech
              </a>
            </li>
            <li>
              <a href="https://careers.reallygood.co.il/" title="Careers">
                Careers
              </a>
            </li>
          </menu>
          <div className={styles.contact}>
            <a
              href="#footer"
              title="Contact"
              className={styles.popContactUs}
              onClick={() => toggleMenu(false)}
            >
              Contact Us
            </a>
            {/* <a href="#Join Us" title="Join Us" className={styles.popJoinUs}>
							Join Us
						</a> */}
            <Logo fill="#fff" className={styles.logo} />
          </div>
        </aside>
      )}
      <menu className={styles.menu}>
        <li>
          <a href="https://reallygood.co.il/" title="Home">
            Home
          </a>
        </li>
        <li>
          <a href="https://reallygood.co.il/#why" title="Why">
            Why
          </a>
        </li>
        <li>
          <a href="https://reallygood.co.il/#clients" title="Clients">
            Clients
          </a>
        </li>
        <li>
          <a href="https://reallygood.co.il/#team" title="Team">
            Team
          </a>
        </li>
        <li>
          <a href="https://reallygood.co.il/#tech" title="Tech">
            Tech
          </a>
        </li>
        <li>
          <a href="https://careers.reallygood.co.il/" title="Careers">
            Careers
          </a>
        </li>
        <li>
          <a href="#footer" title="Contact" className={styles.decoration}>
            Contact
          </a>
        </li>
      </menu>
    </nav>
  );
}

export default Navbar;
