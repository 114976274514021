import React from 'react';

export const Watchful = (props) => (
  <svg
    {...props}
    width="180px"
    height="32px"
    viewBox="0 0 180 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Watchful Logo</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Clientelle"
        transform="translate(-331.000000, -204.000000)"
        fillRule="nonzero"
      >
        <g id="bla" transform="translate(331.000000, 204.000000)">
          <path
            d="M42.8252788,0.0970260223 L29.8438662,13.0884758 L29.8438662,0.334572491 C29.8407474,0.202148638 29.7597967,0.0840331426 29.6374185,0.0333439686 C29.5150404,-0.0173452054 29.3742798,0.00893723034 29.2784387,0.100371747 L16.2869888,13.0884758 L16.2869888,0.334572491 C16.2839649,0.202945509 16.2040238,0.0853293903 16.0827477,0.0340758248 C15.9614717,-0.0171777406 15.8214108,0.00767701434 15.7249071,0.0970260223 L0.0970260223,15.7249071 C0.0352157073,15.7872247 0,15.8713361 0,15.9591078 L0,31.3026022 C0.0031187833,31.4350261 0.0840695083,31.5531416 0.206447657,31.6038308 C0.328825805,31.6545199 0.469586324,31.6282375 0.565427509,31.536803 L13.5568773,18.5486989 L13.5568773,31.3026022 C13.5599961,31.4350261 13.6409468,31.5531416 13.763325,31.6038308 C13.8857031,31.6545199 14.0264636,31.6282375 14.1223048,31.536803 L27.1003717,18.5486989 L27.1003717,31.3026022 C27.1034905,31.4350261 27.1844413,31.5531416 27.3068194,31.6038308 C27.4291976,31.6545199 27.5699581,31.6282375 27.6657993,31.536803 L43.2903346,15.9156134 C43.3521449,15.8532957 43.3873606,15.7691843 43.3873606,15.6814126 L43.3873606,0.334572491 C43.3843367,0.202945509 43.3043955,0.0853293903 43.1831195,0.0340758248 C43.0618434,-0.0171777406 42.9217826,0.00767701434 42.8252788,0.0970260223 Z"
            id="Path"
            fill="#F4442E"
          ></path>
          <polygon
            id="Path"
            fill="#151921"
            points="71.5650558 8.40780669 75.3959108 21.004461 79.3572491 8.40780669 83.0910781 8.40780669 77.4736059 24.5107807 73.5791822 24.5107807 71.695539 19.1877323 70.1364312 13.6037175 68.5472119 19.1877323 66.6635688 24.5107807 62.769145 24.5107807 57.1182156 8.40780669 60.8921933 8.40780669 64.8535316 21.004461 68.6843866 8.40780669"
          ></polygon>
          <path
            d="M96.8855019,8.44126394 L100.130855,8.44126394 L100.130855,24.4472119 L96.9490706,24.4472119 L96.7884758,21.8509294 C95.7144981,23.8951673 93.2486989,24.8620818 91.2680297,24.8620818 C86.5840149,24.895539 83.0542751,21.8743494 83.0542751,16.4208178 C83.0542751,11.067658 86.7345725,8.07657993 91.3349442,8.07657993 C93.6033457,8.07657993 95.7814126,9.1472119 96.7884758,10.9672862 L96.8855019,8.44126394 Z M86.4,16.4275093 C86.4,19.739777 88.7420074,21.7505576 91.5925651,21.7505576 C98.5081784,21.7505576 98.5081784,11.1345725 91.5925651,11.1345725 C88.735316,11.1345725 86.4,13.1486989 86.4,16.4275093 Z"
            id="Shape"
            fill="#151921"
          ></path>
          <path
            d="M108.210781,3.83085502 L108.210781,8.47472119 L112.894796,8.47472119 L112.894796,11.3754647 L108.177323,11.3754647 L108.177323,18.9936803 C108.177323,20.6665428 108.92342,21.6200743 110.482528,21.6200743 C111.195674,21.5930396 111.893907,21.4079107 112.526766,21.0780669 L113.500372,23.935316 C112.494705,24.4047925 111.398329,24.6480509 110.288476,24.6479554 C106.812268,24.7449814 104.834944,22.7643123 104.834944,19.0003717 L104.834944,11.3754647 L101.750186,11.3754647 L101.750186,8.47472119 L104.834944,8.47472119 L104.834944,4.18884758 L108.210781,3.83085502 Z"
            id="Path"
            fill="#151921"
          ></path>
          <path
            d="M128.174721,22.3695167 C126.588482,24.0040118 124.396267,24.9087549 122.118959,24.8687732 C117.50855,24.8687732 113.677695,21.9780669 113.677695,16.4609665 C113.677695,10.9438662 117.46171,8.05315985 122.118959,8.05315985 C124.292598,7.99929447 126.389414,8.85840675 127.900372,10.4219331 L125.755762,12.660223 C124.783132,11.7382341 123.492583,11.2265678 122.152416,11.2315985 C119.26171,11.2315985 117.02342,13.2791822 117.02342,16.4609665 C117.02342,19.836803 119.365428,21.6200743 122.118959,21.6200743 C123.53519,21.6644972 124.90771,21.1263724 125.916357,20.1312268 L128.174721,22.3695167 Z"
            id="Path"
            fill="#151921"
          ></path>
          <path
            d="M132.87881,1.7197026 L132.87881,10.8100372 C134.273978,8.86282528 136.157621,8.21375465 138.074721,8.21375465 C142.584758,8.21375465 144.662454,11.2249071 144.662454,15.8118959 L144.662454,24.4472119 L141.316729,24.4472119 L141.316729,15.8453532 C141.316729,12.8342007 139.757621,11.3319703 137.301859,11.3319703 C134.705576,11.3319703 132.885502,13.4732342 132.885502,16.102974 L132.885502,24.4472119 L129.539777,24.4472119 L129.539777,1.7197026 L132.87881,1.7197026 Z"
            id="Path"
            fill="#151921"
          ></path>
          <path
            d="M148.817844,24.4472119 L148.817844,11.5895911 L145.766543,11.5895911 L145.766543,8.63531599 L148.817844,8.63531599 L148.817844,7.46765799 C148.817844,3.47286245 151.3171,1.39516729 154.595911,1.39516729 C156.003868,1.42001537 157.372049,1.86636392 158.523792,2.67657993 L157.225651,5.17583643 C156.517588,4.6551599 155.668396,4.36120892 154.789963,4.33271375 C153.264312,4.33271375 152.19368,5.33643123 152.19368,7.48104089 L152.19368,8.64869888 L157.031599,8.64869888 L157.031599,11.602974 L152.19368,11.602974 L152.19368,24.4472119 L148.817844,24.4472119 Z"
            id="Path"
            fill="#151921"
          ></path>
          <path
            d="M161.933086,8.44126394 L161.933086,17.0130112 C161.933086,19.739777 163.492193,21.6200743 166.282528,21.6200743 C168.912268,21.6200743 170.728996,19.4486989 170.728996,16.7855019 L170.728996,8.44126394 L174.074721,8.44126394 L174.074721,24.4773234 L171.063569,24.4773234 L170.869517,22.1085502 C169.310409,23.8951673 167.590706,24.7081784 165.349071,24.7081784 C161.407807,24.7081784 158.587361,21.8810409 158.587361,17.0431227 L158.587361,8.44126394 L161.933086,8.44126394 Z"
            id="Path"
            fill="#151921"
          ></path>
          <polygon
            id="Path"
            fill="#151921"
            points="179.782528 1.75315985 179.782528 24.4472119 176.436803 24.4472119 176.436803 1.75315985"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);
