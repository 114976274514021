import React from 'react';

import { Hexagons } from '../Hexagons/Hexagons';

import * as styles from './index.module.scss';

export function About() {
  return (
    <section className={styles.about}>
      <section className={styles.textContent}>
        <h2>About Really Good</h2>
        <p>
          Since 2012, Really Good has developed complex and beautiful web apps
          that stretch the limits of what’s possible in the browser, while
          maintaining a high standard of craftsmanship and a healthy work/life
          balance.
        </p>
        <h3>Tech Stack</h3>
        <p className={styles.techStack}>
          We’re as flexible as the dynamic Front End world requires us to be.
          Nowadays it’s a lot of React apps but we love Vue, Angular, Svelte and
          whatever comes next. We usually work with TypeScript, write unit-tests
          for our code and organize components with Storybook.
        </p>
      </section>
      <Hexagons />
    </section>
  );
}
