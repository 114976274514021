import React from 'react';

export const Envelope = (props) => (
  <svg
    {...props}
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.04459 0.927734C2.25862 0.927734 0.794586 2.39177 0.794586 4.17773V13.6777C0.794586 15.4637 2.25862 16.9277 4.04459 16.9277H17.5446C19.3306 16.9277 20.7946 15.4637 20.7946 13.6777V4.17773C20.7946 2.39177 19.3306 0.927734 17.5446 0.927734H4.04459ZM4.04459 2.42773H17.5446C18.5196 2.42773 19.2946 3.2027 19.2946 4.17773V4.73047L10.7946 9.3252L2.29459 4.73047V4.17773C2.29459 3.2027 3.06956 2.42773 4.04459 2.42773ZM2.29459 6.43555L10.4381 10.8379C10.5476 10.897 10.6701 10.928 10.7946 10.928C10.919 10.928 11.0415 10.897 11.151 10.8379L19.2946 6.43555V13.6777C19.2946 14.6528 18.5196 15.4277 17.5446 15.4277H4.04459C3.06956 15.4277 2.29459 14.6528 2.29459 13.6777V6.43555Z"
      fill="#333333"
    />
  </svg>
);
