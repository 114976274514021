import React from 'react';

export const Border = (props) => (
  <svg
    {...props}
    viewBox="0 0 163 187"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      vectorEffect="non-scaling-stroke"
      opacity="0.5"
      d="M78.3617 3.2205C80.3728 2.05939 82.8506 2.05939 84.8617 3.2205L158.19 45.5568C160.201 46.7179 161.44 48.8638 161.44 51.186V135.859C161.44 138.181 160.201 140.327 158.19 141.488L84.8617 183.824C82.8506 184.985 80.3728 184.985 78.3617 183.824L5.03301 141.488C3.0219 140.327 1.783 138.181 1.783 135.859V51.186C1.783 48.8638 3.0219 46.7179 5.033 45.5568L78.3617 3.2205Z"
      stroke="black"
      strokeLinecap="round"
    />
  </svg>
);
