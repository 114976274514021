import React from 'react';

export const Facebook = (props) => (
  <svg
    {...props}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.6746 0.799316H3.91459C2.03779 0.799316 0.514587 2.32252 0.514587 4.19932V25.9593C0.514587 27.8361 2.03779 29.3593 3.91459 29.3593H25.6746C27.5514 29.3593 29.0746 27.8361 29.0746 25.9593V4.19932C29.0746 2.32252 27.5514 0.799316 25.6746 0.799316ZM22.9546 10.9993H21.5946C20.1394 10.9993 19.5546 11.3393 19.5546 12.3593V14.3993H22.9546L22.2746 17.7993H19.5546V27.9993H16.1546V17.7993H13.4346V14.3993H16.1546V12.3593C16.1546 9.63932 17.5146 7.59932 20.2346 7.59932C22.2066 7.59932 22.9546 8.27932 22.9546 8.27932V10.9993Z"
      fill="#1E1D1D"
    />
  </svg>
);
