import React from 'react';

export const Via = (props) => (
  <svg
    {...props}
    width="180px"
    height="80px"
    viewBox="0 0 180 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Via Logo</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Clientelle"
        transform="translate(-575.000000, -179.000000)"
        fill="#00A8E2"
      >
        <g
          id="60919d5d15b7324b8aa8d9d8_via-logo"
          transform="translate(575.000000, 179.000000)"
        >
          <path
            d="M115.222667,27.3413333 C115.639556,26.4113333 115.777778,25.3802222 115.620667,24.3731111 C115.463556,23.366 115.018,22.426 114.337778,21.6670889 C113.756,21.0340667 113.027556,20.5538222 112.216444,20.2686444 C111.405556,19.9834667 110.536889,19.9021111 109.686889,20.0317333 C108.837111,20.1613333 108.032222,20.4979778 107.343111,21.0119556 C106.654,21.5259333 106.101778,22.2014889 105.735333,22.9791111 L95.3322222,46.4997778 L85.0213333,23.2197778 C84.9906667,23.1264444 84.9282222,23.0331111 84.8993333,22.9446667 L84.8377778,22.8224444 C84.5615556,22.2648889 84.1782222,21.7674667 83.7093333,21.3584222 C83.2031111,20.8945778 82.6104444,20.5352222 81.9648889,20.3009778 C81.3195556,20.0667556 80.6342222,19.9622222 79.9484444,19.9934222 C79.2624444,20.0246 78.5895556,20.1908889 77.968,20.4827333 C77.3464444,20.7745778 76.7886667,21.1862444 76.3266667,21.6941111 C75.6884444,22.4077778 75.2548889,23.2806667 75.0724444,24.2204444 C74.8897778,25.1604444 74.9648889,26.132 75.2897778,27.0326667 L75.3204444,27.126 C75.3811111,27.2788889 75.4417778,27.4308889 75.5071111,27.5531111 L90.5464444,61.5702222 C91.0808889,62.704 91.9995556,63.612 93.1397778,64.1326667 C95.7322222,65.2917778 98.844,64.1624444 100.034,61.5702222 L115.134889,27.4328889 L115.134889,27.4028889 L115.222667,27.3413333 Z"
            id="Path"
          ></path>
          <path
            d="M130.322889,25.178 C130.291556,23.8315556 129.734667,22.5511111 128.771556,21.6100444 C127.808222,20.669 126.515111,20.1421333 125.168444,20.1421333 C123.821778,20.1421333 122.528667,20.669 121.565556,21.6100444 C120.602222,22.5511111 120.045333,23.8315556 120.014,25.178 L120.014,59.4011111 C120.004889,60.0837778 120.131333,60.7617778 120.386444,61.3953333 C120.641333,62.0288889 121.019556,62.6053333 121.499333,63.0915556 C121.978889,63.5775556 122.550222,63.9635556 123.180444,64.2271111 C123.810444,64.4904444 124.486444,64.6262222 125.169333,64.6262222 C125.852222,64.6262222 126.528444,64.4904444 127.158444,64.2271111 C127.788444,63.9635556 128.36,63.5775556 128.839556,63.0915556 C129.319111,62.6053333 129.697556,62.0288889 129.952444,61.3953333 C130.207333,60.7617778 130.334,60.0837778 130.324667,59.4011111 L130.322889,25.178 Z"
            id="Path"
          ></path>
          <path
            d="M179.010889,42.2893333 C179.010889,29.9951111 169.033111,20.0200667 156.741778,20.0200667 C144.450222,20.0200667 134.467778,29.9951111 134.467778,42.2893333 C134.467778,54.5835556 144.445556,64.5596179 156.738,64.5596179 C160.098444,64.5675556 163.416,63.8053333 166.436,62.3317778 L166.710222,56.3833333 L162.561556,53.4244444 L162.012222,52.9973333 C160.371778,53.8082222 158.565111,54.2257778 156.735111,54.2171111 C154.376444,54.2157778 152.070889,53.5151111 150.110222,52.2037778 C148.149556,50.8922222 146.621778,49.0288889 145.72,46.8493333 C144.818222,44.6697778 144.582889,42.2717778 145.044,39.9584444 C145.504889,37.6451111 146.641556,35.5202222 148.309778,33.8528889 C149.978222,32.1853333 152.103556,31.05 154.417111,30.5902222 C156.730667,30.1306667 159.128667,30.3671111 161.307778,31.2702222 C163.486889,32.1731111 165.349333,33.702 166.659778,35.6633333 C167.97,37.6246667 168.669556,39.9304444 168.669556,42.2893333 L168.669556,59.4008889 C168.687778,60.7642222 169.242222,62.0657778 170.212889,63.0233333 C171.183333,63.9811111 172.492,64.518 173.855556,64.518 C175.219111,64.518 176.527778,63.9811111 177.498444,63.0233333 C178.469111,62.0657778 179.023556,60.7642222 179.041778,59.4008889 L179.041778,42.2893333 L179.010889,42.2893333 Z"
            id="Path"
            fillRule="nonzero"
          ></path>
          <path
            d="M66.5951111,42.7471111 C65.7315556,43.6115556 64.7055556,44.2966667 63.5762222,44.7633333 C62.4468889,45.2297778 61.2364444,45.4686667 60.0146667,45.4657778 C58.7926667,45.4628889 57.5833333,45.2184444 56.4562222,44.7466667 C55.3291111,44.2748889 54.3064444,43.5846667 53.4468889,42.7162222 C52.6435556,41.8917778 51.9928889,40.9313333 51.5248889,39.8795556 C50.6486667,42.6666667 49.11,45.2 47.0404444,47.2622222 C39.9328889,54.4006667 28.3706667,54.4006667 21.2630889,47.2622222 C17.8622667,43.8506667 15.9439778,39.2357778 15.9244667,34.4188889 C15.8987556,32.3288889 15.0684667,30.3293333 13.6062667,28.8357778 C12.5004667,27.7286667 11.0918889,26.9731111 9.5578,26.6646667 C8.02368889,26.356 6.43257778,26.508 4.98466667,27.1015556 C3.53673333,27.6948889 2.29666667,28.7033333 1.42050222,30 C0.544333333,31.2964444 0.0711933333,32.8233333 0.0606131111,34.388 L0,34.388 C0.0159645333,43.4144444 3.60306667,52.0675556 9.97786667,58.458 C16.8411333,65.352 33.8948889,82.222 33.8948889,82.222 C33.8948889,82.222 51.7384444,64.9557778 58.2064444,58.458 C62.7682222,53.8811111 65.9411111,48.1062222 67.358,41.8015556 C67.1304444,42.1373333 66.8751111,42.4535556 66.5951111,42.7471111 L66.5951111,42.7471111 Z"
            id="Path"
            fillRule="nonzero"
          ></path>
          <path
            d="M68.212,33.9608889 C68.146,24.9895556 64.5635556,16.4021333 58.2342222,10.0438222 C44.8993333,-3.34793333 23.3042222,-3.34793333 10.0028889,10.0438222 C5.3152,14.7388222 2.09406444,20.6957333 0.73188,27.1888889 C1.00516889,26.7884444 1.32266667,26.42 1.67837778,26.0904444 C2.53804444,25.2206667 3.56177778,24.53 4.69028889,24.0586667 C5.81877778,23.5873333 7.0296,23.3446667 8.25257778,23.3446667 C9.47555556,23.3446667 10.6863556,23.5873333 11.8148667,24.0586667 C12.9433556,24.53 13.9671111,25.2206667 14.8267778,26.0904444 C15.6295556,26.904 16.2804222,27.8546667 16.7486667,28.8973333 C17.6206667,26.0662222 19.1694889,23.4902222 21.2610889,21.3924667 C28.3686667,14.2541111 39.9308889,14.2541111 47.0384444,21.3924667 C50.3788889,24.7324444 52.2933333,29.238 52.3788889,33.9608889 L52.3788889,34.6891111 C52.4673333,36.6697778 53.282,38.5486667 54.6673333,39.9671111 C55.7493333,41.0584444 57.1253333,41.8115556 58.6277778,42.1348889 C60.13,42.4582222 61.694,42.338 63.1293333,41.7884444 C64.5644444,41.2391111 65.8088889,40.2844444 66.7113333,39.0404444 C67.6137778,37.7966667 68.1351111,36.3171111 68.212,34.7824444 L68.212,34.4475556 C68.2426667,34.2868889 68.2426667,34.1215556 68.212,33.9608889 Z"
            id="Path"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
