import React from 'react';

export default function AngularIcon(props) {
  return (
    <svg
      {...props}
      width="83px"
      height="88px"
      viewBox="0 0 83 88"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>angular-icon</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Artboard"
          transform="translate(-714.000000, -511.000000)"
          fillRule="nonzero"
        >
          <g id="Polygon-5-(3)" transform="translate(675.000000, 463.000000)">
            <g id="angular-icon" transform="translate(39.000000, 48.000000)">
              <polygon
                id="Path"
                fill="#E23237"
                points="0 14.575 40.9608961 0 83 14.3495 76.2061903 68.508 40.9608961 88 6.28771209 68.761"
              ></polygon>
              <polygon
                id="Path"
                fill="#B52E31"
                points="83 14.3495 40 0 40 88 76.0509029 68.508"
              ></polygon>
              <path
                d="M40.9313545,10 L15,66.9007545 L24.6767544,66.7077771 L29.9021458,53.849971 L53.0950598,53.849971 L58.7731323,66.8070226 L68,67 L40.9313545,10 Z M40.995624,28.266686 L49.7698107,46.3431515 L33.3447567,46.3431515 L41.0207729,28.266686 L40.995624,28.266686 Z"
                id="Shape"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
